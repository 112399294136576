import React, { useState } from 'react'
import { Box, DataTable } from 'grommet'
import styled from 'styled-components'

import { pubList, bookList, monoGraphs } from './data'

const Button = styled.button`
  padding: 10px 15px;
  margin-right: -1px;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid transparent;
  background: ${p => (p.active ? '#f3f3f3' : 'none')};
  border-bottom: ${p => (p.active ? '1px solid black' : 'none')};
  transition: 0.3s ease;
`

const prepareTableData = data => data.map((d, i) => ({ index: i + 1, ...d }))

const Pubs = () => {
  const [selectedData, setSelectedData] = useState(pubList)
  return (
    <div style={{ overflowX: 'auto' }}>
      <Box direction="row" pad={{ vertical: 'small' }}>
        <Button
          active={selectedData === pubList}
          onClick={() => setSelectedData(pubList)}
        >
          Artykuły
        </Button>
        <Button
          active={selectedData === bookList}
          onClick={() => setSelectedData(bookList)}
        >
          Książki
        </Button>
        <Button
          active={selectedData === monoGraphs}
          onClick={() => setSelectedData(monoGraphs)}
        >
          Monografie
        </Button>
      </Box>
      <DataTable
        columns={[
          { header: 'L.p.', property: 'index' },
          { header: 'Publikacja', property: 'articleName' },
          {
            header: 'Pkt. MNiSW',
            property: 'point',
            align: 'center',
          },
          {
            header: 'IF',
            property: 'if',
            align: 'center',
          },
        ]}
        data={prepareTableData(selectedData)}
        primaryKey={false}
        sortable
        replace
        a11yTitle="publications table"
      />
    </div>
  )
}

export default Pubs
