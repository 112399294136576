import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Anchor } from 'grommet'

const Coop = () => {
  const data = useStaticQuery(graphql`
    query {
      coop: file(relativePath: { eq: "coop-logos.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return (
    <>
      <ul>
        <li>
          <Anchor
            href="http://www.sggw.pl/en/"
            target="_blank"
            label="Warsaw University of Life Sciences"
          />
        </li>
        <li>
          <Anchor
            href="https://ivh.ku.dk/english/"
            target="_blank"
            label="University of Copenhagen, Department of Veterinary and Animal Sciences"
          />
        </li>

        <li>
          <Anchor
            href="https://www.pw.edu.pl/engpw/Academics/Faculties/Faculty-of-Materials-Science-and-Engineering"
            target="_blank"
            label="Warsaw Technical University, Faculty of Material Sciences"
          />
        </li>

        <li>
          <Anchor
            href="http://www.elka.pw.edu.pl/eng/Faculty/Faculty-Institutes2/Institute-of-Microelectronics-and-Optoelectronics"
            target="_blank"
            label="Warsaw Technical University, Faculty of Electronics and Information Technology, Institute of Microelectronics and Optoelectronics"
          />
        </li>

        <li>
          <Anchor
            href="http://www.iim.p.lodz.pl/indexeng.html"
            target="_blank"
            label="Technical University of Lodz, Institute of Materials Science and Engineering"
          />
        </li>

        <li>
          <Anchor
            href="https://tu.koszalin.pl/eng"
            target="_blank"
            label="Koszalin Technical University, Institute of Mechatronics, Nanotechnology and Vacuum Technique"
          />
        </li>

        <li>
          <Anchor
            href="http://www.entermentalhealth.net/members/warsaw.html"
            target="_blank"
            label="Institute of Psychiatry and Neurology in Warsaw"
          />
        </li>

        <li>
          <Anchor
            href="http://www.tut.ac.za/"
            target="_blank"
            label="Tschwane University of Pretoria"
          />
        </li>
        <li>
          <Anchor
            href="http://www.cicancer.org/en"
            target="_blank"
            label="Cancer Research Center-IBMCC (USAL-CSIC), Salamanca, Spain"
          />
        </li>
        <li>
          <Anchor
            href="https://ibsal.es/en/"
            target="_blank"
            label="Hematology Department, University Hospital of Salamanca, Institute of Biomedical Research of Salamanca (IBSAL), Spain"
          />
        </li>
        <li>
          <Anchor
            href="https://cskmswia.pl/en/homepage"
            target="_blank"
            label="The Central Clinical Hospital of the Ministry of the Interior and Administration in Warsaw"
          />
        </li>
        <li>
          <Anchor
            href="https://www.ibb.waw.pl/en"
            target="_blank"
            label="The Institute of biochemistry and biophysics PAS"
          />
        </li>
        <li>
          <Anchor
            href="http://www.ichtj.waw.pl/ichtj/"
            target="_blank"
            label="The Institute of Nuclear Chemistry and Technology"
          />
        </li>
      </ul>
      <Img fluid={data.coop.childImageSharp.fluid} />
    </>
  )
}

export default Coop
