import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Tabs, Tab } from 'grommet'

import Grants from '../components/science/grants'
import Patents from '../components/science/patents'
import Pubs from '../components/science/pubs'
import Coop from '../components/science/coop'

import { Layout } from '../layout'
import SEO from '../components/seo'

const tabIndexes = {
  pubs: 0,
  grants: 1,
  patents: 2,
  coop: 3,
}

const SciencePage = ({ location }) => {
  const [index, setIndex] = useState(0)
  const target = location?.state?.target

  useEffect(() => {
    setIndex(target ? tabIndexes[target] : 0)
  }, [target])

  const onActive = nextIndex => setIndex(nextIndex)
  return (
    <Layout>
      <SEO title="Nauka" />
      <Heading>Działalność naukowa</Heading>
      <Tabs activeIndex={index} onActive={onActive}>
        <Tab title="Publikacje">
          <Box pad="medium">
            <Pubs />
          </Box>
        </Tab>
        <Tab title="Granty">
          <Box pad="medium">
            <Grants />
          </Box>
        </Tab>
        <Tab title="Patenty">
          <Box pad="medium">
            <Patents />
          </Box>
        </Tab>
        <Tab title="Współpraca naukowa">
          <Box pad="medium">
            <Coop />
          </Box>
        </Tab>
      </Tabs>
    </Layout>
  )
}

export default SciencePage

SciencePage.propTypes = { location: PropTypes.object }
