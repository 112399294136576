import React from 'react'
import { Heading } from 'grommet'

const PatentsPage = () => (
  <>
    <Heading level={3}>Uzyskane patenty:</Heading>
    2020
    <ul>
      <li>
        Patent UE EP 3 105 177 B1 (08.07.2020 Bulletin 2020/28). Suspension of
        graphene oxide nanoflakes in water, its use and a method of preparation
        thereof.
      </li>
      <li>
        Patent URP P.423414 (22.04.2020). Sposób wielostronnej oceny
        biozgodności materiałów.
      </li>
    </ul>
    2019
    <ul>
      <li>
        US 10,471,095 B2. Suspension of graphene oxide nanoflakes in water, its
        use and a method of preparation thereof. Ewa Sawosz Chwalibóg, Marta
        Kutwin, Sławomir Jaworski, Mateusz Wierzbicki, Marta Grodzik, Anna
        Hotowy, Ludwika Lipińska, Joanna Jagiełło.
      </li>
    </ul>
    2018
    <ul>
      <li>
        Patent UPRP P.415237. Urządzenie do higieny wymienia i strzyków zwierząt
        Gołębiewski M., Wójcik A., Wierzbicki M.
      </li>
      <li>
        Patent UPRP P.415181. Urządzenie do kąpieli wymienia i strzyków zwierząt
        Gołębiewski M., Wójcik A., Wierzbicki M.
      </li>
      <li>
        Patent UPRP P.415178. Ściereczka jednorazowego użytku do higieny
        przedudojowej gruczołu mlekowego zwierząt Gołębiewski M., Wójcik A.,
        Wierzbicki M.
      </li>
      <li>
        Patent UPRP P-415244. Preparat do natrysku do przedudojowej higieny
        wymienia i strzyków zwierząt Gołębiewski M., Wójcik A., Wierzbicki M.
      </li>
      <li>
        Patent UPRP P-415243. Preparat do przedudojowej kąpieli wymienia i
        strzyków zwierząt Gołębiewski M., Wójcik A., Wierzbicki M.
      </li>
      <li>
        Patent UPRP P-415238. Preparat do natrysku do poudojowej higieny
        wymienia i strzyków zwierząt. Gołębiewski M., Wójcik A., Wierzbicki M.
        Patent UPRP P-415241 Preparat do poudojowej kąpieli wymienia i strzyków
        zwierząt Gołębiewski M., Wójcik A., Wierzbicki M.
      </li>
      <li>
        Patent UPRP P-415241. Preparat do poudojowej kąpieli wymienia i strzyków
        zwierząt. Gołębiewski M., Wójcik A., Wierzbicki M.
      </li>
      <li>
        Patent UPRP P-415242. Preparat do zastosowania w profilaktyce i leczeniu
        zakażeń gruczołu mlekowego u zwierząt Gołębiewski M., Wójcik A.,
        Wierzbicki M.
      </li>
    </ul>
    2017
    <ul>
      <li>
        Patent UPRP P 407166. Zawiesina wodna nanopłatków tlenku grafenu
        dekorowanych nanocząstkami metalicznej platyny, jej zastosowanie i
        sposób jej wytwarzania. Ewa Sawosz, Marta Grodzik, Mateusz Wierzbicki,
        Sławomir Jaworski, Marta Kutwin, Anna Hotowy, Ludwika Lipińska, Joanna
        Jagiełło.
      </li>
    </ul>
    <Heading level={3}>Zgłoszenia patentowe:</Heading>
    2019
    <ul>
      <li>
        P.430970. Medium do hodowli komórek mięśniowych oraz zastosowanie
        ekstraktu z zarodka kury. Ewa Sawosz Chwalibóg, Jasmina Bałaban, Anna
        Hotowy, Malwina Sosnowska, Marta Grodzik, Jarosław Szczepaniak, Mateusz
        Wierzbicki, Marta Kutwin, Sławomir Jaworski, Andrzej Chwalibóg, Barbara
        Strojny-Cieślak, Marlena Zielińska-Górska.
      </li>
    </ul>
    2017
    <ul>
      <li>
        Zgłoszenie URP P423414. Sposób wielostronnej oceny biozgodności
        materiałów. Ewa Sawosz Chwalibóg, Marta Grodzik, Anna Hotowy, Malwina
        Sosnowska, Bogumiła Urbanowska, Jarosław Szczepaniak, Mateusz
        Wierzbicki, Sławomir Jaworski, Andrzej Chwalibóg, Marta Kutwin, Barbara
        Strojny, Natalia Kurantowicz.
      </li>
      <li>
        Zgłoszenie URP P422317. Dodatek mineralny do paszy, sposób wytwarzania
        dodatku do paszy oraz zastosowanie. Ewa Sawosz, Monika Łukasiewicz, Jan
        Niemiec, Andrzej Chwalibog, Andrzej Łozicki, Sławomir Jaworski, Damian
        Józefiak, Jan Jankowski.
      </li>
      <li>
        Zgłoszenie P422848. Fermentowany makuch rzepakowy dla zwierząt. Damian
        Józefiak, Ewa Sawosz i wsp.
      </li>
      <li>
        P422849. Sposób uszlachetniania makuchów rzepakowych. Damian Józefiak,
        Ewa Sawosz i wsp.
      </li>
    </ul>
  </>
)

export default PatentsPage
