import React from 'react'
import { Box, Button, Heading, Layer } from 'grommet'
import { Close } from 'grommet-icons';

const Grants = () => {
  const [showModal, setShowModal] = React.useState()
  return (
    <>
      <Heading level={3}>Źródło finansowania: NCBiR</Heading>
      <ul>
        <li>
          Biostrateg 267659/7/NCBR/2015. "Gutfeed" innowacyjne żywienie w zrównoważonej produkcji
          drobiarskiej. Kierownik - Ewa Sawosz Chwalibóg
        </li>
        <li>
          Lider LIDER/144/L-6/14/NCBR/2015 2016-2018. Identyfikacja markerów molekularnych w
          komórkach glejaka wielopostaciowego wskazujących na wrażliwość tych komórek na działanie
          płatków grafenowych lub ich pochodnych. Kierownik - Marta Grodzik
        </li>
        <li>
          Szpitale-Jednoimienne/23/2020 - Grafeno-metaliczny aerosol (MetaGrafen) jako
          długoterminowy i nietoksyczny środek przeciwko koronawirusowi SARS-CoV-2. Kierownik: Ewa
          Sawosz Chwalibóg -{' '}
          <Button label="Więcej informacji" onClick={() => setShowModal(true)} size="small" />
        </li>
      </ul>

      <Heading level={3}>Źródło finansowania: NCN</Heading>
      <ul>
        <li>Sonata 2016/23/D/NZ9/01401. Kierownik - Sławomir Jaworski</li>
        <li>
          Preludium 2016/21/N/NZ703344. Mechanizm interakcji nanocząstek węglowych diamentu,
          tlenku grafenu oraz grafitu z enzymami cytochromu P450 - badania na modelach in vitro,
          in vivo oraz in silico. Kierownik - Barbara Strojny
        </li>
        <li>
          Preludium 2016/21/N/NZ6/01529. Wpływ nanoimmunostymulatorów na aktywację leukocytów i
          ich wybranych subpopulacji. Kierownik - Natalia Kurantowicz
        </li>
        <li>
          Opus 2016/21/B/N29/01029. Odżywcze nanotratwy i węglowe nisze jako modulatory
          proliferacji, różnicowania i potencjalnego formowania pseudo-tkanki przez mięśniowe
          komórki progenitorowe. Kierownik - Ewa Sawosz Chwalibóg
        </li>
        <li>
          Sonata 2016/23/D/NZ7/03837. Nanostrukturalne platformy jako systemy nośnikowe
          molekularnych sygnałów programowanej śmierci komórki w badaniach modelowych glejaka
          wielopostaciowego. Kierownik - Marta Kutwin
        </li>
        <li>Miniatura 2017/01/X/NZ9/00195. Kierownik - Magdalena Matusiewicz </li>
        <li>
          Preludium UMO-2019/33/N/NZ7/01392. Alotropy węgla jako aktywatory
          mezenchymalnoepitelialnej transformacji fenotypu komórek nowotworowych wątroby poprzez
          ścieżki zależne od cytokin. Kierownik - Malwina Sosnowska
        </li>
        <li>
          OPUS 19 - Zależne od mikrośrodowiska zaburzenie nowotworowych naczyń włosowatych przez
          nanocząstki diamentu w leczeniu silnie unaczynionych nowotworów. Kierownik: Mateusz
          Wierzbicki
        </li>
      </ul>
      <Heading level={3}>Źródło finansowania: MNiSzW</Heading>
      <ul>
        <li>
          8707/E-385/SPUB/2017/1. Wniosek o przyznanie środków finansowych na utrzymanie
          specjalnego urządzenia badawczego. Kierownik - Mateusz Wierzbicki
        </li>
      </ul>
      {showModal && (
        <Layer onEsc={() => setShowModal(false)} onClickOutside={() => setShowModal(false)}>
          <Button
            alignSelf="end"
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowModal(false)}
          />

          <Box pad={{bottom:'medium', horizontal: 'medium'}} overflow={{ vertical: 'scroll' }}>
            <Heading level={4} fill>
              Celem projektu było opracowanie produktu biobójczego MetaGrafen, w tym przeciwko
              wirusowi SARS-CoV-2, oraz technologii jego zastosowania do bezpiecznej dla człowieka
              dezynfekcji pomieszczeń szpitalnych, miejsc użytkowania publicznego, odzieży
              ochronnej, sprzętów, narzędzi i innych powierzchni.
            </Heading>
            <p>
              Badania, przeprowadzone w ramach projektu NCBiR we współpracy z Centralnym Szpitalem
              Klinicznym MSWiA, pozwoliły na opracowanie nowatorskiej metody zastosowania
              MetaGrafenu - kompleksu tlenku grafenu (GO), inkrustowanego nanocząstkami Ag, jako
              preparatu biobójczego.
            </p>

            <p>
              GO ma silne tendencje do osadzania się na większości materiałów co powoduje, że
              „przytrzymuje” nanoczastki Ag i pozwala na ich długotrwałe działanie
              antymikrobiologiczne. Preparat można w łatwy sposób, poprzez natryskiwanie,
              tworzenie aerozolu, namaczanie nanosić na większość powierzchni tworząc trwałą,
              nietoksyczną nano-warstwę antymikrobiologiczną. Większość dotychczasowych rozwiązań
              cechuje działanie krótkookresowe, nasze rozwiązanie pozwala na nadanie różnym
              powierzchniom długotrwałych właściwości bakteriobójczych. Istotną cechą preparatu
              jest możliwość łatwego, samodzielnego naniesienia MetaGrafenu na większość
              powierzchni. Preparat jest polecany do tworzenia antymikrobiologicznej nanowarstwy
              na powierzchniach maseczek, odzieży ochronnej, wyposażenia sklepów, ścian, mebli,
              wyposażenia szkół, przedszkoli i pomieszczeń szpitalnych.
            </p>
            <p>
              Skuteczność biobójczą preparatu zweryfikowano w badaniach na mikroorganizmach
              normatywnych i innych, a między innymi na bakteriach i wirusach jak: Pseudomonas
              aeruginosa, Staphylococcus aureus, Escherichia coli, mysi koronawirus MHV, ludzki
              koronawirus OC43 i SARS-CoV- 2. Preparat został pozytywnie zweryfikowany pod
              względem bezpieczeństwa w badaniach na ludzkich komórkach skóry, nabłonka rogówki,
              płuc, a także modelu zarodka kury oraz ludzkiej skóry 3D.
            </p>
            <ul>
              <li>
                Preparat MetaGrafen został zgłoszony jako patent do UPRP P437795 z dnia 07.05.2021
                „Preparat o działaniu wirusobójczym i biobójczym, jego zastosowanie zwłaszcza
                przeciw SARS-CoV- 2, sposób wytwarzania preparatu i warstwy ochronnej o
                własnościach bójczych”.
              </li>
              <li>
                Preparat MetaGrafen został zgłoszony do rejestracji w Urzędzie Rejestracji
                Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych jako produkt
                biobójczy pod nazwą MetaGrafen w dniu 30.01.2022 jako „Płyn przeznaczony do
                dezynfekcji powierzchni (mających również kontakt z żywnością) o działaniu
                bakteriobójczym i wirusobójczym wobec wirusów bezosłonkowych i osłonkowych, w tym
                koronawirusom, do stosowania m.in. w placówkach związanych z ochroną zdrowia.
                Produkt nadaje się do zamgławiania pomieszczeń.
              </li>
            </ul>
          </Box>
        </Layer>
      )}
    </>
  )
}

export default Grants
