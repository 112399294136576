export const pubList = [
  {
    articleName:
      'Lechowski Roman, Sawosz Chwalibóg Ewa, Kluciński Włodzimierz: The Effect of the Addition of Oil Preparation with Increased Content of n-3 Fatty Acids on Serum Lipid Profile and Clinical Condition of Cats with Miliary Dermatitis, w: Journal Of Veterinary Medicine Series A-Physiology Pathology Clinical Medicine, vol. 45, 1998, ss. 417-424',
    point: '–',
    if: '0.627',
  },
  {
    articleName:
      'Lechowski Roman, Sawosz Chwalibóg Ewa, Kluciński Włodzimierz, Chachułowa Jadwiga, Siwicki Andrzej: Influence of stress on the concentration of acute phase protein (APP) concentration, on gamma-globulins, activity of lysozyme and total protein in pig sera, w: Medycyna Weterynaryjna, vol. 54, 1998, ss. 619-621',
    point: '10',
    if: '0.203',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Chachułowa Jadwiga, Lechowski Roman, Fiedorowicz Szymon: Influence of dietary lecithin and oils rich in n-3 polyunsaturated fatty acids (PUFA) on the fatty acid content of rat muscle and serum, w: Journal of Animal and Feed Sciences, vol. 8, 1999, ss. 485-497, DOI:10.22358/jafs/69113/1999',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Lechowski Roman, Bielecki Wojciech, Sawosz Chwalibóg Ewa, Krawiec M., Kluciński Włodzimierz: The Effect of Lecithin Supplementation on the Biochemical Profile and Morphological Changes in the Liver of Rats Fed Different Animal Fats, w: Veterinary Research Communications, vol. 23, 1999, ss. 1-14, DOI:10.1023/A:1006156104465',
    point: '25',
    if: '0.377',
  },
  {
    articleName:
      'Fiedorowicz Szymon, Lechowski Roman, Sawosz Chwalibóg Ewa: The effect of using oxygenated oil and vitamin E, sodium ascorbinate and guar gum in the nutrition of porkers on their hematologic parameters in systemic blood, w: Medycyna Weterynaryjna, vol. 55, 1999, ss. 694-697',
    point: '10',
    if: '0.203',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Chachułowa Jadwiga, Lechowski Roman, Fiedorowicz Szymon, Kosieradzka Iwona: Effect of different dietary fats on the content of fatty acids in the the blood serum and in the longissimus dorsi muscle of rats, w: Journal of Animal and Feed Sciences, vol. 8, 1999, ss. 95-105, DOI:10.22358/jafs/68812/1999',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Winnicka Anna, Sawosz Chwalibóg Ewa, Kluciński Włodzimierz, Kosieradzka Iwona, Szopa J., Malepszy S., Pastuszewska B.: A note on the effect of feeding genetically modified potatoes on selected indices of non-specific resistance in rats, w: Journal of Animal and Feed Sciences, vol. 10, 2001, ss. 13-18, DOI:10.22358/jafs/70026/2001',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Kowalczyk E., Hotowy Anna, Lechowski Roman, Kleczkowski Mirosław, Fabijańska M.: The effect of a diet fortified with polyunsaturated fatty acids on the level of selected elements in the myocardium of growing pigs, w: Journal of Animal and Feed Sciences, vol. 10, 2001, ss. 177-182, DOI:10.22358/jafs/70052/2001',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Kosieradzka Iwona, Sawosz Chwalibóg Ewa, Pastuszewska B., Szwacka M., Malepszy S., Bielecki Wojciech, Czumińska K.: The effect of feeding diets with genetically modified cucumbers on the growth and health status of rats, w: Journal of Animal and Feed Sciences, vol. 10, 2001, ss. 7-12, DOI:10.22358/jafs/70025/2001',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Winnicka Anna, Kluciński Włodzimierz, Kosieradzka Iwona, Pastuszewska B., Kleczkowski Mirosław, Skomiał Jacek, Niemiec Tomasz: The effect of a high-fat diet on redox homeostasis indicators and nonspecific immunity in rats, w: Journal of Animal and Feed Sciences, vol. 13, 2004, ss. 341-351, DOI:10.22358/jafs/67418/2004',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Kosieradzka Iwona, Sawosz Chwalibóg Ewa, Pastuszewska B., Zuk M., Szopa J., Bielecki Wojciech: Effect of feeding potato tubers modified by 14-3-3 protein overexpression on metabolism and health status of rats, w: Journal of Animal and Feed Sciences, vol. 13, 2004, ss. 329-339, DOI:10.22358/jafs/67417/2004',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Rekiel Anna, Gajewska Julitta, Topol Katarzyna, Sawosz Chwalibóg Ewa: Effect of intensity of feeding on the intestinal microflora of pigs, w: Polish Journal of Microbiology, Polskie Towarzystwo Mikrobiologów, vol. 54, 2005, ss. 331-334',
    point: '15',
    if: '0.66',
  },
  {
    articleName:
      "Pierzynowski S.G., Kruszewska D., Rengman S., Fed'kiv O., Da̧bek M., Hotowy Anna, Werpachowska E., Weström B.R.: Relations between pig growth and regulatory mechanism of pancreas - Facts and hypotheses, w: Journal of Animal and Feed Sciences, vol. 14, 2005, ss. 139-144, DOI:10.22358/jafs/70359/2005",
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Grodzik Marta, Sałamaszyńska Agnieszka, Klimuszko Danuta: Mechanisms of pathogenicity of Campylobacter jejuni, w: Medycyna Weterynaryjna, vol. 61, 2005, ss. 1124-1127',
    point: '10',
    if: '0.203',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Chwalibog A., Niemiec Tomasz, Kosieradzka Iwona, Skomiał Jacek: Effect of nutrition on oxidative stress, w: Journal of Animal and Feed Sciences, vol. 14, 2005, ss. 87-97, DOI:10.22358/jafs/70356/2005',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Da̧bek M., Kruszewska D., Filip Rafał, Hotowy Anna, Pierzynowski Ł., Wojtasz-Paja̧k A., Szymanczyk S., Valverde Piedra J.L., Werpachowska E., Pierzynowski S.G.: α-Ketoglutarate (AKG) absorption from pig intestine and plasma pharmacokinetics, w: Journal of Animal Physiology and Animal Nutrition, vol. 89, 2005, ss. 419-426, DOI:10.1111/j.1439-0396.2005.00566.x',
    point: '30',
    if: '1.075',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Chwalibog A., Skomiał Jacek, Kosieradzka Iwona, Ziȩcik A.J.: The effect of dietary energy concentration on the hormone profile and lipid metabolism in growing pigs, w: Journal of Animal and Feed Sciences, vol. 14, 2005, ss. 393-396, DOI:10.22358/jafs/70588/2005',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Niemiec Tomasz, Sawosz Chwalibóg Ewa, Chwalibog André: Vitamin C affects the antioxidative/oxidative status in rats irradiated with ultraviolet (UV) and infrared (IR) light, w: Journal of Animal and Feed Sciences, vol. 15, 2006, ss. 77-80',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Vaško Vitalij, Kosieradzka Iwona, Sawosz Chwalibóg Ewa: The effect of tomato consumption on selected protein and lipid metabolism parameters as well as on the oxidative and functional status of rat livers, w: Journal of Animal and Feed Sciences, vol. 15, 2006, ss. 93-96',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa: The influence of silver nanoparticles on chicken embryo dewelopment and bursa of Fabricius morphology, w: Journal of Animal and Feed Sciences, vol. 15, 2006, ss. 111-114',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Więcek Justyna, Skomiał Jacek, Zwierko-Pastuszewska Barbara, Batorska Martyna, Rekiel Anna, Sawosz Chwalibóg Ewa: Serum tryglicerydes and cholesterol concentration in rats given meat and fat from pigs fed on two feeding levels with oil supplementation, w: Polish Journal of Natural Sciences, 2006, ss. 587-592',
    point: '8',
    if: '—',
  },
  {
    articleName:
      'Hansen Michael, Chwalibog André, Tauson Anne-Helene, Sawosz Chwalibóg Ewa: Influence of different fibre sources on digestibility and nitrogen and energy balances in growing pigs, w: Archives of Animal Nutrition, vol. 60, nr 5, 2006, ss. 390-401',
    point: '30',
    if: '1.051',
  },
  {
    articleName:
      'Niemiec Tomasz, Ostaszewska Teresa, Sawosz Chwalibóg Ewa, Szmidt Maciej: The effect of dietary L-ascorbic acid megadoses on the morphology of the lens in rats, w: Journal of Animal and Feed Sciences, vol. 15, 2006, ss. 73-76',
    point: '20',
    if: '0.281',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Binek Marian, Grodzik Marta, Zielińska-Górska Marlena, Sysa Paweł, Szmidt Maciej, Niemiec Tomasz, Chwalibog André: Influence of hydrocolloidal silver nanoparticles on gastrointestinal microflora and morphology of enterocytes of quails, w: Archives of Animal Nutrition, vol. 61, nr 6, 2007, ss. 444-451',
    point: '30',
    if: '1.469',
  },
  {
    articleName:
      'Sokół Janusz, Sawosz Chwalibóg Ewa, Strawa Anna, Rekiel Anna, Grodzik Marta, Niemiec Tomasz: Wpływ dodatku ubichinonu oraz witamin E i C do wysokoenergetycznej diety na stan redoks u tuczników, w: Roczniki Naukowe Polskiego Towarzystwa Zootechnicznego, nr 3, 2007, ss. 69-77',
    point: '5',
    if: '—',
  },
  {
    articleName:
      'Kosieradzka Iwona, Sawosz Chwalibóg Ewa, Szopa Jan, Bielecki Wojciech: Potato genetically modified by 14-3-3 protein repression in growing rat diets Cz. 2. Health status of experimental animals, w: Polish Journal of Food and Nutrition Sciences, vol. 58, nr 3, 2008, ss. 377-382',
    point: '8',
    if: '0.643',
  },
  {
    articleName:
      'Kosieradzka Iwona, Sawosz Chwalibóg Ewa, Szopa Jan, Vasko Vitali: Potato genetically modified by 14-3-3 protein repression in growing rat diets Cz. 1. Chemical composition and digestibility of nutrients, w: Polish Journal of Food and Nutrition Sciences, vol. 58, nr 1, 2008, ss. 125-129',
    point: '8',
    if: '0.643',
  },
  {
    articleName:
      'Chwalibog André, Sawosz Chwalibóg Ewa, Niemiec Tomasz, Grodzik Marta, Thorbek Grete: Nitrogen excretion in rats on a protein-free diet and during starvation, w: Archives of Animal Nutrition, vol. 62, nr 1, 2008, ss. 82-85',
    point: '30',
    if: '0.893',
  },
  {
    articleName:
      'Sokół Janusz, Sawosz Chwalibóg Ewa, Niemiec Tomasz, Majchrzak Katarzyna, Chrzanowski Szczepan, Albera-Łojek Anna, Chwalibog André: Taurine protects DNA of lymphocytes against oxidative alteration in riding horses, w: Animal Science Papers and Reports, vol. 27, nr 1, 2009, ss. 33-40',
    point: '20',
    if: '0.349',
  },
  {
    articleName:
      "Zielińska Arlena, Sawosz Chwalibóg Ewa, Grodzik Marta, Chwalibog André, Kamaszewski Maciej: Influence of nanoparticles of gold on chicken embryos' development, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 46, 2009, ss. 249-253",
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Grodzik Marta, Zielińska-Górska Marlena, Niemiec Tomasz, Olszańska Bożena, Chwalibog André: Nanoparticles of silver do not affect growth, development and DNA oxidative damage in chicken embryos, w: Archiv Fur Geflugelkunde, vol. 73, nr 3, 2009, ss. 208-213',
    point: '20',
    if: '0.448',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Winnicka Anna, Chwalibog André, Niemiec Tomasz, Grodzik Marta, Sikorska Justyna: Phagocytic and oxidative-burst activity of blood leukocytes in rats fed a protein-free diet, w: Bulletin of the Veterinary Institute in Pulawy, vol. 53, nr 4, 2009, ss. 775-778',
    point: '20',
    if: '0.218',
  },
  {
    articleName:
      'Niemiec Tomasz, Sawosz Chwalibóg Ewa, Grodzik Marta, Chwalibog André: High L-ascorbic acid content of diet modulates the non-specific immunity in rats, w: Animal Science Papers and Reports, vol. 27, nr 2, 2009, ss. 149-157',
    point: '20',
    if: '0.349',
  },
  {
    articleName:
      'Gniazdowska-Piekarska Agnieszka, Krasuska Urszula, Czajkowska Karolina, Wierzbicki Mateusz, Bogatek-Leszczyńska Renata: Tlenek azotu i hemoglobiny roślinne, w: Postępy Biologii Komórki, Fundacja Biologii Komórki i Biologii Molekularnej, nr 2, 2009, ss. 233-250',
    point: '15',
    if: '0.077',
  },
  {
    articleName:
      "Studnicka Aneta, Sawosz Chwalibóg Ewa, Grodzik Marta, Chwalibog André, Balcerak Marek: Influence of nanoparticles of silver/palladium alloy on chicken embryos' development, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 46, 2009, ss. 237-242",
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Sikorska Justyna, Szmidt Maciej, Sawosz Chwalibóg Ewa, Niemiec Tomasz, Grodzik Marta, Chwalibog André: Can silver nanoparticles affect the mineral content, structure and mechanical properties of chicken embryo bones?, w: Journal of Animal and Feed Sciences, vol. 19, nr 2, 2010, ss. 286-291',
    point: '20',
    if: '0.659',
  },
  {
    articleName:
      'Chwalibog A., Metges C.C., Sawosz Chwalibóg Ewa, Grodzik Marta, Niemiec Tomasz: Incorporation of nitrogen isotope 15 N into liver DNA during avian embryogenesis. A new approach for measuring the rate of DNA synthesis, w: Journal of Animal and Feed Sciences, vol. 19, 2010, ss. 269-276, DOI:10.22358/jafs/66287/2010',
    point: '20',
    if: '0.659',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Chwalibog André, Szeliga Jacek, Sawosz Filip, Grodzik Marta, Niemiec Tomasz, Kacprzyk Katarzyna: Visualization of gold and platinum nanoparticles interacting with Salmonella Enteritidis and Listeria monocytogenes, w: International journal of nanomedicine, vol. 5, 2010, ss. 631-637',
    point: '40',
    if: '4.976',
  },
  {
    articleName:
      'Chwalibog André, Sawosz Chwalibóg Ewa, Hotowy Anna, Szeliga Jacek, Mitura Stanisław, Bakowicz-Mitura Katarzyna, Grodzik Marta, Orłowski Piotr, Sokołowska Aleksandra: Visualization of interaction between inorganic nanoparticles and bacteria or fungi, w: International journal of nanomedicine, vol. 5, 2010, ss. 1085-1094',
    point: '40',
    if: '4.976',
  },
  {
    articleName:
      'Chwalibog André, Metges Cornelia, Sawosz Chwalibóg Ewa, Grodzik Marta, Niemiec Tomasz: Incorporation of nitrogen isotope 15N into liver DNA during avian embryogenesis. A new approach for measuring the rate of DNA synthesis, w: Journal of Animal and Feed Sciences, vol. 19, nr 2, 2010, ss. 269-276',
    point: '20',
    if: '0.659',
  },
  {
    articleName:
      "Sokół Janusz, Kosieradzka Iwona, Sawosz Chwalibóg Ewa, Fiedorowicz Szymon, Tywończuk Jan, Sobotka Wiesław, Śmiecińska Katarzyna: Nutritive value of wheat distiller's dried grains with solubles (WDDGS) in nutrition of growing-finishing pigs, w: Roczniki Naukowe Polskiego Towarzystwa Zootechnicznego, nr 4, 2010, ss. 201-211",
    point: '5',
    if: '—',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Grodzik Marta, Lisowski Paweł, Zwierzchowski Lech, Niemiec Tomasz, Zielińska-Górska Marlena, Szmidt Maciej, Chwalibog André: Influence of hydrocolloids of Ag, Au, and Ag/Cu alloy nanoparticles on the inflammatory state at transcriptional level, w: Bulletin of the Veterinary Institute in Pulawy, vol. 54, nr 1, 2010, ss. 81-85',
    point: '20',
    if: '0.321',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Sawosz Chwalibóg Ewa, Chwalibog André, Ostaszewska Teresa, Kamaszewski Maciej, Grodzik Marta, Skomiał Jacek: Nano-nutrition of chicken embryos. Effect of gold and taurine nanoparticles on muscle development, w: Journal of Animal and Feed Sciences, vol. 19, nr 2, 2010, ss. 277-285',
    point: '20',
    if: '0.659',
  },
  {
    articleName:
      'Niemiec Tomasz, Sikorska Justyna, Harrison Adrian, Szmidt Maciej, Sawosz Chwalibóg Ewa, Wirth-Dzięciołowska Elżbieta, Wilczak Jacek, Pierzynowski Stefan: Alpha-ketoglutarate stabilizes redox homeostasis and improves arterial elasticity in aged mice, w: Journal of Physiology and Pharmacology, vol. 62, nr 1, 2011, ss. 37-43',
    point: '25',
    if: '2.267',
  },
  {
    articleName:
      'Zielinska Marlena, Sawosz Chwalibóg Ewa, Grodzik Marta, Wierzbicki Mateusz, Gromadka Maria, Hotowy Anna, Sawosz Filip, Łozicki Andrzej, Chwalibog Andrè: Effect of heparan sulfate and gold nanoparticles on muscle development during embryogenesis, w: International journal of nanomedicine, vol. 6, 2011, ss. 3163-3172',
    point: '40',
    if: '3.13',
  },
  {
    articleName:
      'Arkuszewska Ewa, Dymnicka Maria, Fiedorowicz Szymon, Niemiec Jan, Kosieradzka Iwona, Łozicki Andrzej, Sawosz Chwalibóg Ewa, Skomiał Jacek, Sokół Janusz, Stępińska Małgorzata: Suszone wywary zbożowe w żywieniu bydła, świń i drobiu, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 5, 2011, ss. 11-17, ',
    point: '4',
    if: '—',
  },
  {
    articleName:
      'Urbańska Kaja, Sokołowska Justyna, Szmidt Maciej, Grodzik Marta, Sysa Paweł: Glejak wielopostaciowy u psów, w: Życie Weterynaryjne, Krajowa Izba Lekarzy Weterynarii, nr 10, 2011, ss. 780-782.',
    point: '4',
    if: '—',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Chwalibog André, Mitura Katarzyna, Mitura Stanislaw, Szeliga Jacek, Niemiec Tomasz, Rupiewicz Marlena, Grodzik Marta, Sokolowska Aleksandra: Visualisation of morphological interaction of diamond and silver nanoparticles with Salmonella Enteritidis and Listeria monocytogenes, w: Journal of Nanoscience and Nanotechnology, vol. 11, 2011, ss. 7635-7641, DOI:10.1166/jnn.2011.4735',
    point: '25',
    if: '1.563',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa, Wierzbicki Mateusz, Orlowski Piotr, Hotowy Anna, Niemiec Tomasz, Szmidt Maciej, Mitura Katarzyna, Chwalibog André: Nanoparticles of carbon allotropes inhibit glioblastoma multiforme angiogenesis in ovo, w: International journal of nanomedicine, vol. 6, 2011, ss. 3041-3048',
    point: '40',
    if: '3.13',
  },
  {
    articleName:
      'Niemiec Tomasz, Szmidt Maciej, Sawosz Chwalibóg Ewa, Grodzik Marta, Bakowicz-Mitura Katarzyna: The effect of diamond nanoparticles on redox and immune parameters in rats, w: Journal of Nanoscience and Nanotechnology, vol. 11, nr 10, 2011, ss. 9072-9077',
    point: '25',
    if: '1.563',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Sawosz Chwalibóg Ewa, Grodzik Marta, Wierzbicki Mateusz, Gromadka Maria, Hotowy Anna, Sawosz Filip, Łozicki Andrzej, Chwalibog André: Effect of heparan sulfate and gold nanoparticles on muscle development during embryogenesis, w: International journal of nanomedicine, vol. 6, 2011, ss. 3163-3172',
    point: '40',
    if: '3.13',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa, Wierzbicki Mateusz, Orłowski Piotr, Hotowy Anna, Niemiec Tomasz, Szmidt Maciej, Bakowicz-Mitura Katarzyna, Chwalibog André: Nanoparticles of carbon allotropes inhibit glioblastoma multiforme angiogenesis in ovo, w: International journal of nanomedicine, vol. 6, 2011, ss. 3041-3048',
    point: '40',
    if: '3.13',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Chwalibog André, Bakowicz-Mitura Katarzyna, Mitura Stanisław, Szeliga Jacek, Niemiec Tomasz, Rupiewicz Marlena, Grodzik Marta, Sokołowska Aleksandra: Visualisation of morphological interaction od diamond and silver nanoparticles with Salmonella Enteritidis and Listeria monocytogenes, w: Journal of Nanoscience and Nanotechnology, vol. 11, nr 9, 2011, ss. 7635-7641',
    point: '25',
    if: '1.563',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Sawosz Chwalibóg Ewa, Grodzik Marta, Balcerak Marek, Wierzbicki Mateusz, Skomiał Jacek, Sawosz Filip, Chwalibog André: Effect of taurine and gold nanoparticles on the morphological and molecular characteristics of muscle development during chicken embryogenesis, w: Archives of Animal Nutrition, vol. 66, nr 1, 2012, ss. 1-13',
    point: '30',
    if: '1.095',
  },
  {
    articleName:
      'Sawosz Filip, Pineda Lane, Hotowy Anna, Jaworski Sławomir, Kutwin Marta: Influence of Ag nanoparticles, ATP and biocomplex of Ag nanoparticles with ATP on morphology of chicken embryo pectoral muscles, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 51, 2012, ss. 127-132',
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Wierzbicki Mateusz, Sawosz Chwalibóg Ewa, Grodzik Marta: Caveolin-1 localization in chicken embryo chorioallantoic membrane treated with diamond and graphite nanoparticles, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 51, 2012, ss. 133-138',
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Pineda Lane, Sawosz Chwalibóg Ewa, Hotowy Anna, Elnif Jan, Sawosz Filip, Ali Abdalla, Chwalibog André: Effect of nanoparticles of silver and gold on metabolic rate and development of broiler and layer embryos, w: Comparative Biochemistry and Physiology, Part A, vol. 161, nr 3, 2012, ss. 315-319',
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Hotowy Anna, Sawosz Chwalibóg Ewa, Pineda Lane, Sawosz Filip, Grodzik Marta, Chwalibog André: Silver nanoparticles administered to chicken affect VEGFA and FGF2 gene expression in breast muscle and heart, w: Nanoscale Research Letters, Springer, vol. 7, 2012, DOI:10.1186/1556-276X-7-418',
    point: '30',
    if: '2.481',
  },
  {
    articleName:
      'Pineda Lane, Chwalibog Andrè, Sawosz Chwalibóg Ewa, Lauridsen Charlotte, Engberg Ricarda, Elnif Jan, Hotowy Anna, Sawosz Filip, Gao Yuhong, Ali Abdalla: Effect of silver nanoparticles on growth performance, metabolism and microbial profile of broiler chickens, w: Archives of Animal Nutrition, vol. 66, 2012, ss. 416-429, DOI:10.1080/1745039X.2012.710081, ',
    point: '30',
    if: '1.095',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa, Wierzbicki Mateusz, Hotowy Anna, Prasek M., Jaworski Sławomir, Chwalibog A.: VEGF-dependent mechanism of anti-angiogenic action of diamond nanoparticles in glioblastoma multiforme tumor, w: Technical Proceedings of the 2012 NSTI Nanotechnology Conference and Expo, NSTI-Nanotech 2012, 2012, ss. 218-221',
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Szmidt Maciej, Urbańska Kaja, Grodzik Marta, Orłowski Piotr, Sawosz Chwalibóg Ewa, Wierzbicki Mateusz, Sysa Paweł: Morphology of human glioblastoma model cultured in ovo, w: Bulletin of the Veterinary Institute in Pulawy, vol. 56, nr 2, 2012, ss. 261-266',
    point: '20',
    if: '0.377',
  },
  {
    articleName:
      'Pineda Lane, Chwalibog André, Sawosz Chwalibóg Ewa, Hotowy Anna, Elnif Jan, Sawosz Filip: Investigating the effect of in ovo injection of silver nanoparticles on fat uptake and development in broiler and layer hatchlings, w: Journal of Nanotechnology, Hindawi Publishing Corporation, 2012, DOI:10.1155/2012/212486',
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Sałamaszyńska-Guz Agnieszka, Grodzik Marta, Klimuszko Danuta: Mutational Analysis of cj0183 Campylobacter jejuni Promoter, w: Current Microbiology, vol. 67, nr 6, 2013, ss. 696-702, DOI:10.1007/s00284-013-0420-8',
    point: '20',
    if: '1.359',
  },
  {
    articleName:
      'Pineda Lane, Sawosz Chwalibóg Ewa, Vadalasetty Krishna, Chwalibog André: Effect of copper nanoparticles on metabolic rate and development of chicken embryos, w: Animal Feed Science and Technology, vol. 186, nr 1/2, 2013, ss. 125-129',
    point: '45',
    if: '2.086',
  },
  {
    articleName:
      'Kutwin Marta, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Grodzik Marta, Ostaszewska Teresa, Kamaszewski Maciej, Wierzbicki Mateusz, Chwalibog André: Influence of nanoparticles of platinum on chicken embryo development and brain morphology, w: Nanoscale Research Letters, Springer, vol. 8, nr 251, 2013',
    point: '35',
    if: '2.481',
  },
  {
    articleName:
      'Zawistowska-Deniziak Anna, Wasyl Katarzyna, Norbury Luke, Wesołowska Agnieszka, Bień Justyna, Grodzik Marta, Wiśniewski Marcin, Bąska Piotr, Wędrychowicz Halina: Characterization and differential expression of cathepsin L3 alleles from Fasciola hepatica, w: Molecular and Biochemical Parasitology, vol. 190, nr 1, 2013, ss. 27-37',
    point: '30',
    if: '2.243',
  },
  {
    articleName:
      'Jaworski Sławomir, Sawosz Chwalibóg Ewa, Grodzik Marta, Kutwin Marta, Wierzbicki Mateusz, Włodyga Karolina, Jasik Agnieszka, Reichert Michał, Chwalibog Andrè: Comparison of tumour morphology and structure from U87 and U118 glioma cells cultured on chicken embryo chorioallantoic membrane, w: Bulletin of the Veterinary Institute in Pulawy, vol. 57, 2013, ss. 593-598, DOI:10.2478/bvip-2013-0101',
    point: '20',
    if: '0.365',
  },
  {
    articleName:
      'Grodzik Marta: Changes in glioblastoma multiforme ultrastructure after diamond nanoparticles treatment. Experimental model in ovo, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 52, 2013, ss. 29-35',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Batorska Martyna, Łukasiewicz Monika, Wnuk-Gnich Agnieszka, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Niemiec Jan: Effect of nanoparticles of copper and copper sulfate administered in ovo on hematological and biochemical blood markers of broiler chickens, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 52, 2013, ss. 141-149',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Szmidt Maciej, Niemiec Tomasz, Sawosz Chwalibóg Ewa, Bakowicz-Mitura Katarzyna: The influence of nanodiamond particles on rat health status, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 52, 2013, ss. 195-201',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Filip, Sawosz Chwalibóg Ewa, Hotowy Anna, Wierzbicki Mateusz, Kutwin Marta, Jaworski Sławomir, Chwalibog André: Nano-nutrition of chicken embryos. The effect of in ovo administration of diamond nanoparticles and l-glutamine on molecular responses in chicken embryo pectoral muscles, w: International Journal of Molecular Sciences, vol. 14, nr 11, 2013, ss. 23033-23044',
    point: '30',
    if: '2.339',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Jaworski Sławomir, Siennicka Anna, Gondek Agata: Unikalne właściwości nanocząsteczek srebra, w: Polskie Drobiarstwo, nr 2, 2013, ss. 6-8',
    point: '3',
    if: '—',
  },
  {
    articleName:
      'Ostaszewska Teresa, Dąbrowski Konrad, Kamaszewski Maciej, Kwasek Karolina, Grodzik Marta, Bierła Joanna: The effect of dipeptide, Lys-Gly, supplemented diets on digestive tract histology in juvenile yellow perch (Perca flavescens), w: Aquaculture Nutrition, vol. 19, nr 1, 2013, ss. 100-109',
    point: '35',
    if: '1.665',
  },
  {
    articleName:
      'Wierzbicki Mateusz, Sawosz Chwalibóg Ewa, Grodzik Marta, Hotowy Anna, Kutwin Marta, Jaworski Sławomir, Sawosz Filip, Chwalibog André: Carbon nanoparticles downregulate expression of basic fibroblast growth factor in the heart during embryogenesis, w: International journal of nanomedicine, vol. 8, 2013, ss. 3427-3435',
    point: '35',
    if: '4.195',
  },
  {
    articleName:
      'Wierzbicki Mateusz, Sawosz Chwalibóg Ewa, Grodzik Marta, Kutwin Marta, Jaworski Sławomir, Chwalibog André: Comparison of anti-angiogenic properties of pristine carbon nanoparticles, w: Nanoscale Research Letters, Springer, vol. 8, nr 195, 2013',
    point: '35',
    if: '2.481',
  },
  {
    articleName:
      'Niemiec Tomasz, Szmidt Maciej, Kruk-Roszkowska Anna, Sawosz Chwalibóg Ewa, Bakowicz-Mitura Katarzyna: Carbon synthetized by RF PACVD method enhances the activity of antioxidants, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 52, 2013, ss. 151-156',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Niemiec Tomasz, Szmidt Maciej, Sawosz Chwalibóg Ewa, Grodzik Marta, Łozicki Andrzej, Strużyński Witold: Od komórki do produkcji zwierzęcej - badania i perspektywy zastosowania nanocząstek srebra, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 6, 2013, ss. 7-10',
    point: '6',
    if: '—',
  },
  {
    articleName:
      'Jaworski Sławomir, Sawosz Chwalibóg Ewa, Grodzik Marta, Winnicka Anna, Kutwin Marta, Wierzbicki Mateusz, Chwalibog André: In vitro evaluation of the effects of graphene platelets on glioblastoma multiforme cells, w: International journal of nanomedicine, vol. 8, 2013, ss. 413-420',
    point: '35',
    if: '4.195',
  },
  {
    articleName:
      'Sawosz Filip, Pineda Lane, Hotowy Anna, Jaworski Sławomir, Kutwin Marta, Sawosz Chwalibóg Ewa, Chwalibog André: Nano-nutrition of chicken embryos. The effect of silver nanoparticles and ATP on expression of chosen genes involved in myogenesis, w: Archives of Animal Nutrition, vol. 67, nr 5, 2013, ss. 347-355',
    point: '30',
    if: '0.892',
  },
  {
    articleName:
      'Wierzbicki Mateusz: Modele zwierzęce w badaniach medycznych, biologicznych i zootechnicznych, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 6, 2014, ss. 26-28',
    point: '6',
    if: '—',
  },
  {
    articleName:
      'Kutwin Marta, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Kurantowicz Natalia, Strojny-Cieślak Barbara, Chwalibog André: Structural damage of chicken red blood cells exposed to platinum nanoparticles and cisplatin, w: Nanoscale Research Letters, Springer, vol. 9, nr 257, 2014, DOI:10.1186/1556-276X-9-257',
    point: '35',
    if: '2.779',
  },
  {
    articleName:
      'Kosieradzka Iwona, Vasko Vitali, Bartoszewski Grzegorz, Szwacka Maria, Fiszdon Katarzyna, Matusiewicz Magdalena: Nutritive and dietetic value of Genetically-Modified tomatoes expressing thaumatin gene, w: Polish Journal of Food and Nutrition Sciences, vol. 64, nr 1, 2014, ss. 35-43, DOI:10.2478/v10222-012-0090-4',
    point: '15',
    if: '0.643',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Jaworski Sławomir, Kutwin Marta, Grodzik Marta, Wierzbicki Mateusz, Hotowy Anna, Chwalibog André: Magia grafenu, w: Agricola, nr 88, 2014, ss. 35-39',
    point: '–',
    if: '—',
  },
  {
    articleName:
      'Matusiewicz Magdalena, Kosieradzka Iwona, Żuk Magdalena, Szopa Jan: Genetically modified flax expressing NAP-SsGT1 transgene: examination of anti-inflammatory action, w: International Journal of Molecular Sciences, vol. 15, nr 9, 2014, ss. 16741-16759, DOI:10.3390/ijms150916741',
    point: '30',
    if: '2.862',
  },
  {
    articleName:
      'Kosieradzka Iwona, Matusiewicz Magdalena: Zamierzone i nieprzewidywalne efekty transgenezy roślin GM2, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 3, 2014, ss. 44-46',
    point: '6',
    if: '—',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Łukasiewicz Monika, Wnuk-Gnich Agnieszka, Sawosz Chwalibóg Ewa, Niemiec Jan: Effect of copper nanoparticles and copper sulfate administered in ovo on copper content in breast muscle, liver and spleen of broiler chickens, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 53, 2014, ss. 135-142',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Hinzmann Mateusz, Jaworski Sławomir, Kutwin Marta, Jagiełło Joanna, Koziński Rafał, Wierzbicki Mateusz, Grodzik Marta, Lipińska Ludwika, Sawosz Chwalibóg Ewa, Chwalibog André: Nanoparticles containing allotropes of carbon have genotoxic effects on glioblastoma multiforme cells, w: International journal of nanomedicine, vol. 9, 2014, ss. 2409-2417, DOI:10.2147/IJN.S62497',
    point: '35',
    if: '4.383',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Jaworski Sławomir, Kutwin Marta, Hotowy Anna, Wierzbicki Mateusz, Grodzik Marta, Kurantowicz Natalia, Strojny-Cieślak Barbara, Lipińska Ludwika, Chwalibog André: Toxicity of pristine graphene in experiments in a chicken embryo model, w: International journal of nanomedicine, vol. 9, 2014, ss. 3913-3922, DOI:10.2147/IJN.S65633',
    point: '35',
    if: '4.383',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Grodzik Marta, Wierzbicki Mateusz, Hotowy Anna, Kutwin Marta, Jaworski Sławomir, Strojny-Cieślak Barbara, Kurantowicz Natalia: Nanocząstki - molekuły sygnalne i transportowe w badaniach biologicznych, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 3, 2014, ss. 41-43',
    point: '6',
    if: '—',
  },
  {
    articleName:
      'Niemiec Tomasz, Makarski Mateusz, Łozicki Andrzej, Sokół Janusz, Sawosz Chwalibóg Ewa: Dodatki paszowe jako modyfikatory metabolizmu oksydoredukcyjnego i odporności zwierząt, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 3, 2014, ss. 37-38',
    point: '6',
    if: '—',
  },
  {
    articleName:
      'Kamaszewski Maciej, Kutwin Marta, Ostaszewska Teresa, Dąbrowski Konrad: The influence of feeding diets containing wheat gluten supplemented with dipeptides or free amino acids on structure and development of the skeletal muscle of carp (Cyprinus carpio), w: Aquaculture International, vol. 22, nr 1, 2014, ss. 259-271',
    point: '20',
    if: '0.984',
  },
  {
    articleName:
      'Osińska Ewa, Godlewski Michał, Wierzbicki Mateusz, Motyl Tomasz: Bone marrow-origin stem/progenitor cells in the mammary gland of heifers, w: Polish Journal of Veterinary Sciences, vol. 17, nr 1, 2014, ss. 161-163, DOI:10.2478/pjvs-2014-0021',
    point: '20',
    if: '0.604',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Sawosz Chwalibóg Ewa, Vadalasetty Krishna, Łukasiewicz Monika, Niemiec Jan, Wierzbicki Mateusz, Kutwin Marta, Jaworski Sławomir, Chwalibog André: Nanoparticles of copper stimulate angiogenesis at systemic and molecular level, w: International Journal of Molecular Sciences, vol. 16, nr 3, 2015, ss. 4838-4849, DOI:10.3390/ijms16034838',
    point: '30',
    if: '3.257',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Górski Konrad: Nanobiotechnology in reproduction-pros and cons. A review, w: Journal of Animal and Feed Sciences, vol. 24, 2015, ss. 179-192, DOI:10.22358/jafs/65623/2015',
    point: '20',
    if: '0.511',
  },
  {
    articleName:
      'Bhanja Subrat, Hotowy Anna, Mehra Manish, Sawosz Chwalibóg Ewa, Pineda Lane, Vadalasetty Krishna, Kurantowicz Natalia, Chwalibog André: In ovo administration of silver nanoparticles and/or amino acids influence metabolism and immune gene expression in chicken embryos, w: International Journal of Molecular Sciences, vol. 16, nr 5, 2015, ss. 9484-9503, DOI:10.3390/ijms16059484',
    point: '30',
    if: '3.257',
  },
  {
    articleName:
      'Urbańska Kaja, Pająk Beata, Orzechowski Arkadiusz, Sokołowska Justyna, Grodzik Marta, Sawosz Chwalibóg Ewa, Szmidt Maciej, Sysa Paweł: The effect of silver nanoparticles (AgNPs) on proliferation and apoptosis of in ovo cultured glioblastoma multiforme (GBM) cells, w: Nanoscale Research Letters, Springer, vol. 10, nr 1, 2015, DOI:10.1186/s11671-015-0823-5',
    point: '35',
    if: '2.584',
  },
  {
    articleName:
      'Matusiewicz Magdalena, Kosieradzka Iwona, Sobczak-Filipiak Małgorzata, Żuk Magdalena, Szopa Jan: Transgenic flax overexpressing polyphenols as a potential anti-inflammatory dietary agent, w: Journal of Functional Foods, vol. 14, 2015, ss. 299-307, DOI:10.1016/j.jff.2015.02.004',
    point: '45',
    if: '3.973',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Jaworski Sławomir, Kutwin Marta, Vadalasetty Krishna, Grodzik Marta, Wierzbicki Mateusz, Kurantowicz Natalia, Strojny-Cieślak Barbara, Hotowy Anna, Lipińska Ludwika: Graphene functionalized with arginine decreases the development of glioblastoma multiforme tumor in a gene-dependent manner, w: International Journal of Molecular Sciences, vol. 16, nr 10, 2015, ss. 25214-25233, DOI:10.3390/ijms161025214, ',
    point: '30',
    if: '3.257',
  },
  {
    articleName:
      'Matusiewicz Magdalena, Kosieradzka Iwona, Żuk Magdalena, Szopa Jan: Effect of dose and administration period of seed cake of genetically modified and non-modified flax on selected antioxidative activities in rats, w: International Journal of Molecular Sciences, vol. 16, nr 6, 2015, ss. 14259-14275, DOI:10.3390/ijms160614259',
    point: '30',
    if: '3.257',
  },
  {
    articleName:
      'Kurantowicz Natalia, Strojny-Cieślak Barbara, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Kutwin Marta, Grodzik Marta, Wierzbicki Mateusz, Lipińska Ludwika, Bakowicz-Mitura Katarzyna, Chwalibog André: Biodistribution of a high dose of diamond, graphite, and graphene oxide nanoparticles after multiple intraperitoneal injections in rats, w: Nanoscale Research Letters, Springer, vol. 10, nr 1, 2015, DOI:10.1186/s11671-015-1107-9',
    point: '35',
    if: '2.584',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Wierzbicki Mateusz, Strojny-Cieślak Barbara, Hotowy Anna, Urbańska Kaja, Kutwin Marta, Włodyga Karolina, Kurantowicz Natalia: Badania genetyczne i personalizacja terapii w leczeniu glejaków, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 6, 2015, ss. 14-16',
    point: '6',
    if: '—',
  },
  {
    articleName:
      'Beck Iwona, Hotowy Anna, Sawosz Chwalibóg Ewa, Grodzik Marta, Wierzbicki Mateusz, Kutwin Marta, Jaworski Sławomir, Chwalibog André: Effect of silver nanoparticles and hydroxyproline, administered in ovo, on the development of blood vessels and cartilage collagen structure in chicken embryos, w: Archives of Animal Nutrition, vol. 69, nr 1, 2015, ss. 57-68, DOI:10.1080/1745039X.2014.992179',
    point: '30',
    if: '1.319',
  },
  {
    articleName:
      'Kurantowicz Natalia, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Kutwin Marta, Strojny-Cieślak Barbara, Wierzbicki Mateusz, Szeliga Jacek, Hotowy Anna, Lipińska Ludwika, Koziński Rafał: Interaction of graphene family materials with Listeria monocytogenes and Salmonella enterica, w: Nanoscale Research Letters, Springer, vol. 10, nr 1, 2015, DOI:10.1186/s11671-015-0749-y',
    point: '35',
    if: '2.584',
  },
  {
    articleName:
      'Zakrzewska Karolina, Samluk Anna, Wierzbicki Mateusz, Jaworski Sławomir, Kutwin Marta, Sawosz Chwalibóg Ewa, Chwalibog André, Pijanowska Dorota, Pluta Krzysztof: Analysis of the cytotoxicity of carbon-based nanoparticles, diamond and graphite, in human glioblastoma and hepatoma cell lines, w: PLoS ONE, vol. 10, nr 3, 2015, DOI:10.1371/journal.pone.0122579',
    point: '40',
    if: '3.057',
  },
  {
    articleName:
      'Jaworski Sławomir, Sawosz Chwalibóg Ewa, Kutwin Marta, Wierzbicki Mateusz, Hinzmann Mateusz, Grodzik Marta, Winnicka Anna, Lipińska Ludwika, Włodyga Karolina, Chwalibog André: In vitro and in vivo effects of graphene oxide and reduced graphene oxide on glioblastoma, w: International journal of nanomedicine, vol. 10, 2015, ss. 1585-1596, DOI:10.2147/IJN.S77591',
    point: '35',
    if: '4.32',
  },
  {
    articleName:
      'Strojny-Cieślak Barbara, Kurantowicz Natalia, Sawosz Chwalibóg Ewa, Grodzik Marta, Jaworski Sławomir, Kutwin Marta, Wierzbicki Mateusz, Hotowy Anna, Lipińska Ludwika, Chwalibog André: Long term influence of carbon nanoparticles on health and liver status in rats, w: PLoS ONE, vol. 10, nr 12, 2015, DOI:10.1371/journal.pone.0144821',
    point: '40',
    if: '3.057',
  },
  {
    articleName:
      'Gajewska Alina, Zielińska-Górska Marlena, Wasilewska-Dziubinska E., Baran M., Kotarba G., Górski Konrad: Pituitary galaninergic system activity in female rats: The regulatory role of gonadal steroids, w: Journal of Physiology and Pharmacology, vol. 67, 2016, ss. 423-429',
    point: '25',
    if: '2.883',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Łukasiewicz Monika, Siennicka Anna, Sawosz Chwalibóg Ewa, Niemiec Jan: Strategiczna rola miedzi, w: Polskie Drobiarstwo, nr 2, 2016, ss. 20-22',
    point: '3',
    if: '—',
  },
  {
    articleName:
      'Górski Konrad, Marciniak E., Zielińska-Górska Marlena, Misztal T.: Salsolinol Up-Regulates Oxytocin Expression and Release During Lactation in Sheep, w: Journal of Neuroendocrinology, vol. 28, 2016, DOI:10.1111/jne.12362',
    point: '30',
    if: '3.47',
  },
  {
    articleName:
      'Wójcik-Gładysz Anna, Wańkowska Marta, Gajewska Alina, Misztal Tomasz, Zielińska-Górska Marlena, Szlis Michał, Polkowska Jolanta: Effects of intracerebroventricular infusions of ghrelin on secretion of follicle-stimulating hormone in peripubertal female sheep, w: Reproduction Fertility and Development, vol. 28, 2016, ss. 2065-2074, DOI:10.1071/RD16028',
    point: '30',
    if: '2.656',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Grodzik Marta, Wierzbicki Mateusz, Hotowy Anna, Kutwin Marta, Jaworski Sławomir, Kurantowicz Natalia, Strojny-Cieślak Barbara: Dylematy nanobiotechnologii, w: Przegląd Hodowlany, Polskie Towarzystwo Zootechniczne, nr 5, 2016, ss. 3-5',
    point: '6',
    if: '—',
  },
  {
    articleName:
      'Szmidt Maciej, Sawosz Chwalibóg Ewa, Urbańska Kaja, Jaworski Sławomir, Kutwin Marta, Hotowy Anna, Wierzbicki Mateusz, Grodzik Marta, Lipińska Ludwika, Chwalibog André: Toxicity of different forms of graphene in a chicken embryo model, w: Environmental Science and Pollution Research, vol. 23, nr 19, 2016, ss. 19940-19948, DOI:10.1007/s11356-016-7178-z',
    point: '30',
    if: '2.741',
  },
  {
    articleName:
      'Halik Gabriela, Łozicki Andrzej, Dymnicka Maria, Arkuszewska Ewa, Zielińska-Górska Marlena, Rutkowska Hanna: The effect of feeding ration, enriched in synthetic and natural ß-carotene on the selected indicators of health condition of the cows and on reproduction parameters, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 55 (2), 2016, ss. 167-175',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Strojny-Cieślak Barbara, Grodzik Marta, Sawosz Chwalibóg Ewa, Gromadka Maria: Analysis of a fusion between rat glioma cells and biomimetic liposomes with encapsulated diamond nanoparticles or curcumin, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 55 (2), 2016, ss. 275-281',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Łukasiewicz Monika, Wnuk Agnieszka, Sawosz Chwalibóg Ewa, Niemiec Jan, Skot Abdullah, Jaworski Sławomir, Chwalibog André: In ovo administration of copper nanoparticles and copper sulfate positively influences chicken performance, w: Journal of the Science of Food and Agriculture, vol. 96, 2016, ss. 3058-3062',
    point: '35',
    if: '2.463',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Łukasiewicz Monika, Wnuk-Gnich Agnieszka, Sawosz Chwalibóg Ewa, Niemiec Jan, Skot Abdullah, Jaworski Sławomir, Chwalibog André: In ovo administration of copper nanoparticles and copper sulfate positively influences chicken performance, w: Journal of the Science of Food and Agriculture, vol. 96, nr 6, 2016, ss. 3058-3062, DOI:10.1002/jsfa.7477',
    point: '35',
    if: '2.463',
  },
  {
    articleName:
      'Gajewska Alina, Zielińska-Górska Marlena, Wolinska-Witort Ewa, Siawrys Gabriela, Baran Marta, Kotarba Grzegorz, Biernacka Katarzyna: Intracellular mechanisms involved in copper-gonadotropin-releasing hormone (Cu-GnRH) complex-induced cAMP/PKA signaling in female rat anterior pituitary cells in vitro, w: Brain Research Bulletin, vol. 120, 2016, ss. 75-82, DOI:10.1016/j.brainresbull.2015.11.002',
    point: '25',
    if: '3.033',
  },
  {
    articleName:
      'Scott Abdullah, Vadalasetty Krishna, Sawosz Chwalibóg Ewa, Łukasiewicz Monika, Vadalasetty Radhika, Jaworski Sławomir, Chwalibog André: Effect of copper nanoparticles and copper sulphate on metabolic rate and development of broiler embryos, w: Animal Feed Science and Technology, vol. 220, 2016, ss. 151-158, DOI:10.1016/j.anifeedsci.2016.08.009',
    point: '45',
    if: '1.755',
  },
  {
    articleName:
      'Strojny-Cieślak Barbara, Grodzik Marta, Sawosz Chwalibóg Ewa, Winnicka Anna, Kurantowicz Natalia, Jaworski Sławomir, Kutwin Marta, Urbańska Kaja, Hotowy Anna, Wierzbicki Mateusz: Diamond nanoparticles modify curcumin activity: in vitro studies on cancer and normal cells and in ovo studies on chicken embryo model, w: PLoS ONE, vol. 11, nr 10, 2016, ss. 1-18, DOI:10.1371/journal.pone.0164637, ',
    point: '40',
    if: '2.806',
  },
  {
    articleName:
      'Ostaszewska Teresa, Chojnacki Maciej, Kamaszewski Maciej, Sawosz Chwalibóg Ewa: Histopathological effects of silver and copper nanoparticles on the epidermis, gills, and liver of Siberian sturgeon, w: Environmental Science and Pollution Research, vol. 23, nr 2, 2016, ss. 1621-1633, DOI:10.1007/s11356-015-5391-9',
    point: '30',
    if: '2.741',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Hotowy Anna, Bakowicz-Mitura Katarzyna, Grodzik Marta, Wierzbicki Mateusz, Kutwin Marta, Jaworski Sławomir, Chwalibog André: Carbon allotropes for muscle regeneration, w: Engineering of Biomaterials / Inżynieria Biomateriałów, nr 143, 2017, ss. 49',
    point: '7',
    if: '—',
  },
  {
    articleName:
      'Bugajska Żaneta, Jaworski Sławomir, Daniluk Karolina, Biniecka Paulina, Sosnowska Malwina: Interaction of hierarchical nanoporous carbons (HNCs) with chicken embryo red blood cells (RBC), w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 56 (1), 2017, ss. 37-42, DOI:10.22630/AAS.2017.56.1.5',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Matusiewicz Magdalena, Kosieradzka Iwona, Nowak-Życzyńska Zuzanna, Żuk Magdalena, Szopa Jan: Polyphenol content in cold-pressed cakes of conventional and genetically modified flax as factor affecting stability of the product in different laboratory storage conditions, w: Journal of Animal and Feed Sciences, vol. 26, nr 1, 2017, ss. 70-75, DOI:10.22358/jafs/69335/2017',
    point: '20',
    if: '0.9',
  },
  {
    articleName:
      'Niemiec Tomasz, Dudek Mariusz, Dziekan Natalia, Jaworski Sławomir, Przewozik Aleksandra, Soszka Emilia, Koperkiewicz Anna, Koczoń Piotr: The method of coating Fe3O4 with carbon nanoparticles to modify biological properties of oxide measured in vitro, w: Journal of AOAC International, Proquest, vol. 100, nr 4, 2017, ss. 905-915, DOI:10.5740/jaoacint.17-0165',
    point: '25',
    if: '1.087',
  },
  {
    articleName:
      'Mroczek-Sosnowska Natalia, Łukasiewicz Monika, Adamek-Urbańska Dobrochna, Kamaszewski Maciej, Niemiec Jan, Wnuk-Gnich Agnieszka, Scott Abdullah, Chwalibog André, Sawosz Chwalibóg Ewa: Effect of copper nanoparticles administered in ovo on the activity of proliferating cells and on the resistance of femoral bones in broiler chickens, w: Archives of Animal Nutrition, vol. 71, nr 4, 2017, ss. 327-332, DOI:10.1080/1745039X.2017.1331619',
    point: '30',
    if: '1.887',
  },
  {
    articleName:
      'Kutwin Marta, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Hinzmann Mateusz, Wierzbicki Mateusz, Hotowy Anna, Grodzik Marta, Winnicka Anna, Chwalibog André: Investigation of platinum nanoparticle properties against U87 glioblastoma multiforme, w: Archives of Medical Science, nr 6, 2017, 1322–1334, DOI:10.5114/aoms.2016.58925',
    point: '30',
    if: '2.344',
  },
  {
    articleName:
      'Kutwin Marta, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Wierzbicki Mateusz, Strojny-Cieślak Barbara, Grodzik Marta, Chwalibog André: Assessment of the proliferation status of glioblastoma cell and tumour tissue after nanoplatinum treatment, w: PLoS ONE, vol. 12, nr 5, 2017, ss. 1-14, DOI:10.1371/journal.pone.0178277',
    point: '40',
    if: '2.766',
  },
  {
    articleName:
      'Wierzbicki Mateusz, Jaworski Sławomir, Kutwin Marta, Grodzik Marta, Strojny-Cieślak Barbara, Kurantowicz Natalia, Zdunek Krzysztof, Chodun Rafał, Chwalibog André, Sawosz Chwalibóg Ewa: Diamond, graphite, and graphene oxide nanoparticles decrease migration and invasiveness in glioblastoma cell lines by impairing extracellular adhesion, w: International journal of nanomedicine, vol. 12, 2017, ss. 7241-7254, DOI:10.2147/IJN.S146193',
    point: '35',
    if: '4.37',
  },
  {
    articleName:
      'Chwalibog André, Vadalasetty Krishna, Lauridsen Charlotte, Engberg Ricarda, Vadalasetty Radhika, Kutwin Marta, Sawosz Chwalibóg Ewa: Effect of silver nanoparticles on chicken health after infection with Campylobacter jejuni, w: Engineering of Biomaterials / Inżynieria Biomateriałów, nr 143, 2017, ss. 52',
    point: '7',
    if: '—',
  },
  {
    articleName:
      'Sosnowska Malwina, Sawosz Chwalibóg Ewa, Kutwin Marta, Chwalibog André: Carbon nanoscaffolds for fibroblast and hepatocellular carcinoma cells adhesion, migration and regeneration, w: Engineering of Biomaterials / Inżynieria Biomateriałów, nr 143, 2017, ss. 58',
    point: '7',
    if: '—',
  },
  {
    articleName:
      'Jaworski Sławomir, Biniecka Paulina, Bugajska Żaneta, Daniluk Karolina, Dyjak Sławomir, Strojny-Cieślak Barbara, Kutwin Marta, Wierzbicki Mateusz, Grodzik Marta, Chwalibog André: Analysis of the cytotoxicity of hierarchical nanoporous graphenic carbon against human glioblastoma grade IV cells, w: International journal of nanomedicine, vol. 12, 2017, ss. 3839-3849, DOI:10.2147/IJN.S135932',
    point: '35',
    if: '4.37',
  },
  {
    articleName:
      'Daniluk Karolina, Jaworski Sławomir, Biniecka Paulina, Bugajska Żaneta, Sosnowska Malwina: Influence of melittin on viability and integrity of cell membrane on grade IV glioma, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 56 (1), 2017, ss. 43-51, DOI:10.22630/AAS.2017.56.1.6',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Jaworski Sławomir, Hinzmann Mateusz, Sawosz Chwalibóg Ewa, Grodzik Marta, Kutwin Marta, Wierzbicki Mateusz, Strojny-Cieślak Barbara, Vadalasetty Krishna, Lipińska Ludwika, Chwalibog André: Interaction of different forms of graphene with chicken embryo red blood cells, w: Environmental Science and Pollution Research, vol. 24, nr 27, 2017, 21671–21679, DOI:10.1007/s11356-017-9788-5',
    point: '30',
    if: '2.8',
  },
  {
    articleName:
      'Górski Konrad, Hasiec Małgorzata, Zielińska-Górska Marlena, Fülöp Ferenc, Misztal Tomasz: Up-regulation of oxytocin receptor gene and protein in the sheep anterior pituitary by a dopamine derivative (salsolinol), w: Czech Journal of Animal Science, vol. 62, 2017, ss. 150-156, DOI:10.17221/30/2016-CJAS',
    point: '30',
    if: '0.955',
  },
  {
    articleName:
      'Zawistowska-Deniziak Anna, Basalaj Katarzyna, Strojny-Cieślak Barbara, Młocicki Daniel: New data on human macrophages polarization by Hymenolepis diminuta tapeworm - an in vitro study, w: Frontiers in Immunology, vol. 8, 2017, ss. 1-15, DOI:10.3389/fimmu.2017.00148',
    point: '5',
    if: '—',
  },
  {
    articleName:
      'Herman Andrzej Przemysław, Wojtulewicz Karolina, Bochenek Joanna, Krawczyńska Agata, Antushevich Hanna, Pawlina Bartosz, Zielińska-Górska Marlena, Herman Anna, Romanowicz Katarzyna, Tomaszewska-Zaremba Dorota: Endotoxin-induced inflammation disturbs melatonin secretion in ewe, w: Asian-Australasian Journal of Animal Sciences, vol. 30, 2017, ss. 1784-1795, DOI:10.5713/ajas.17.0196',
    point: '25',
    if: '1.243',
  },
  {
    articleName:
      'Górski Konrad, Misztal T., Marciniak E., Zielińska-Górska Marlena, Fülöp F., Romanowicz K.: Involvement of salsolinol in the suckling-induced oxytocin surge in sheep, w: Domestic Animal Endocrinology, vol. 59, 2017, ss. 75-80, DOI:10.1016/j.domaniend.2016.11.001',
    point: '30',
    if: '1.937',
  },
  {
    articleName:
      'Biniecka Paulina, Jaworski Sławomir, Bugajska Żaneta, Daniluk Karolina: Carbon nanoparticles as transporters of melittin to glioma grade IV U87 cells in in vitro model, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 56 (1), 2017, ss. 23-32, DOI:10.22630/AAS.2017.56.1.3',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Hotowy Anna, Sawosz Chwalibóg Ewa, Grodzik Marta, Wierzbicki Mateusz, Kutwin Marta, Jaworski Sławomir, Chwalibog André: Development and optimization of myocardial tissue culture in ovo, w: Engineering of Biomaterials / Inżynieria Biomateriałów, nr 143, 2017, ss. 54',
    point: '7',
    if: '—',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Sawosz Chwalibóg Ewa, Górski Konrad, Chwalibog André: Does nanobiotechnology create new tools to combat microorganisms?, w: Nanotechnology Reviews, vol. 6, nr 2, 2017, ss. 171-189, DOI:10.1515/ntrev-2016-0042',
    point: '25',
    if: '1.904',
  },
  {
    articleName:
      'Kurantowicz Natalia, Sawosz Chwalibóg Ewa, Halik Gabriela, Strojny-Cieślak Barbara, Hotowy Anna, Grodzik Marta, Piast Radosław, Pasanphan Wanvimol, Chwalibog André: Toxicity studies of six types of carbon nanoparticles in a chicken-embryo model, w: International journal of nanomedicine, vol. 12, 2017, ss. 2887-2898, DOI:10.2147/IJN.S131960',
    point: '35',
    if: '4.37',
  },
  {
    articleName:
      'Sosnowska Malwina, Kutwin Marta, Adamiak Adrian, Gawin Kamil, Bugajska Żaneta, Daniluk Karolina: Green synthesis of silver nanoparticles by using aqueous mint (Mentha piperita) and cabbage (Brassica oleracea var. capitata) extracts and their antibacterial activity, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 56 (1), 2017, ss. 137-145, DOI:10.22630/AAS.2017.56.1.16',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Górski Konrad, Biernacka Katarzyna, Sawosz Chwalibóg Ewa, Kamiński Tadeusz, Gajewska Alina: Gonadotropin-releasing hormone and kisspeptin-10 regulate nuclear receptor subfamily 5 group a member 1/catenin beta 1/nuclear receptor subfamily 0 group b member 1 activity in female rat anterior pituitary gland, w: Journal of Physiology and Pharmacology, vol. 69, nr 3, 2018, ss. 463-474, DOI:10.26402/jpp.2018.3.14',
    point: '25',
    if: '2.544',
  },
  {
    articleName:
      'Niemiec Tomasz, Zglińska Klara, Świderek Wiesław, Górecka Weronika, Matusiewicz Magdalena, Łozicki Andrzej, Kosieradzka Iwona, Pierzynowski Stefan: Wpływ α-ketoglutaranu (AKG) na behawior starzejących się myszy laboratoryjnych, w: Roczniki Naukowe Polskiego Towarzystwa Zootechnicznego, nr 4, 2018, ss. 31-39',
    point: '7',
    if: '—',
  },
  {
    articleName:
      'Sosnowska Malwina, Jankiewicz Urszula, Kutwin Marta, Chwalibog André, Gałązka Agnieszka: Influence of salts and metal nanoparticles on the activity and thermal stability of a recombinant chitinase from Stenotrophomonas maltophilia N4, w: Enzyme and Microbial Technology, vol. 116, 2018, ss. 4-15, DOI:10.1016/j.enzmictec.2018.05.003',
    point: '30',
    if: '3.553',
  },
  {
    articleName:
      'Ngcobo Jabulani, Nedambale Tshimangadzo, Nephawe Khathutshelo, Sawosz Chwalibóg Ewa, Chwalibog André: The future survival of African elephants : implications for conservation, w: International Journal of Avian & Wildlife Biology, vol. 3, nr 5, 2018, ss. 379-384, DOI:10.15406/ijawb.2018.03.00123',
    point: '5',
    if: '—',
  },
  {
    articleName:
      'Widyńska Ewa, Zając Agnieszka, Jaworski Sławomir, Strojny-Cieślak Barbara: Influence of silver and copper nanoparticles on Staphylococcus aureus biofilm formation, w: Annals of Warsaw University of Life Sciences- SGGW Animal Science, nr 57 (2), 2018, ss. 193-201, DOI:10.22630/AAS.2018.57.2.19',
    point: '12',
    if: '—',
  },
  {
    articleName:
      'Wierzbicki Mateusz, Sawosz Chwalibóg Ewa, Strojny-Cieślak Barbara, Jaworski Sławomir, Grodzik Marta, Chwalibog André: NF-κB-related decrease of glioma angiogenic potential by graphite nanoparticles and graphene oxide nanoplatelets, w: Scientific Reports, Nature Publishing Group, vol. 8, 2018, ss. 1-9, DOI:10.1038/s41598-018-33179-3',
    point: '40',
    if: '4.011',
  },
  {
    articleName:
      'Kalińska Aleksandra, Wójcik Agata, Slósarz Jan, Kruzińska Brygida, Michalczuk Monika, Jaworski Sławomir, Wierzbicki Mateusz, Gołębiewski Marcin: Occurrence and aetiology of staphylococcal mastitis – A review, w: Animal Science Papers and Reports, vol. 36, 2018, ss. 263-273',
    point: '25',
    if: '0.697',
  },
  {
    articleName:
      'Kosieradzka Iwona, Matusiewicz Magdalena: The use of genetically modified organisms in the diet of humans and animals, w: BioTechnologia Journal of Biotechnology, Computational Biology and Bionanotechnology, Ośrodek Wydawnictw Naukowych OWN, vol. 99, nr 3, 2018, ss. 225, DOI:10.5114/bta.2018.77492',
    point: '13',
    if: '—',
  },
  {
    articleName:
      'Jaworski Sławomir, Wierzbicki Mateusz, Sawosz Chwalibóg Ewa, Jung Anna, Gielerak Grzegorz, Biernat Joanna, Jaremek Henryk, Łojkowski Witold, Woźniak Bartosz, Kurantowicz Natalia: Graphene oxide-based nanocomposites decorated with silver nanoparticles as an antibacterial agent, w: Nanoscale Research Letters, Springer, vol. 13, nr 1, 2018, ss. 1-17, DOI:10.1186/s11671-018-2533-2, ',
    point: '35',
    if: '3.159',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Łukasiewicz Monika, Łozicki Andrzej, Sosnowska Malwina, Jaworski Sławomir, Niemiec Jan, Scott Abdullah, Jankowski Jan, Józefiak Damian, Chwalibog André: Effect of copper nanoparticles on the mineral content of tissues and droppings, and growth of chickens, w: Archives of Animal Nutrition, vol. 72, nr 5, 2018, ss. 396-406, DOI:10.1080/1745039X.2018.1505146',
    point: '30',
    if: '1.786',
  },
  {
    articleName:
      'Lucht H, Casey Norman, Sawosz Chwalibóg Ewa: Survival and development of embryos of Gallus gallus domesticus treated with inorganic bromide, w: South African Journal of Animal Science, vol. 48, nr 3, 2018, ss. 583-589, DOI:10.4314/sajas.v48i3.19',
    point: '20',
    if: '0.653',
  },
  {
    articleName:
      'Szczepaniak Jarosław, Strojny-Cieślak Barbara, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Jagiełło Joanna, Winkowska Magdalena, Szmidt Maciej, Wierzbicki Mateusz, Winnicka Anna, Grodzik Marta: Effects of reduced graphene oxides on apoptosis and cell cycle of glioblastoma multiforme, w: International Journal of Molecular Sciences, vol. 19, nr 12, 2018, ss. 1-27, DOI:10.3390/ijms19123939, ',
    point: '30',
    if: '4.183',
  },
  {
    articleName:
      'Grodzik Marta, Szczepaniak J., Strojny-Cieślak Barbara, Jaworski Sławomir, Wierzbicki Mateusz, Jagiello J., Soltan E., Mandat T.: Graphenoid plates in glioma therapy, w: TechConnect Briefs 2018 - Advanced Materials, vol. 3, 2018, ss. 125-128',
    point: '5',
    if: '—',
  },
  {
    articleName:
      'Scott Abdullah, Vadalasetty Krishna, Łukasiewicz Monika, Jaworski Sławomir, Wierzbicki Mateusz, Chwalibog André, Sawosz Chwalibóg Ewa: Effect of different levels of copper nanoparticles and copper sulphate on performance, metabolism and blood biochemical profiles in broiler chicken, w: Journal of Animal Physiology and Animal Nutrition, vol. 102, nr 1, 2018, e364-e373, DOI:10.1111/jpn.12754',
    point: '30',
    if: '1.703',
  },
  {
    articleName:
      'Szczepaniak Jaroslaw, Strojny-Cieślak Barbara, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Jagiello Joanna, Szmidt Maciej, Wierzbicki Mateusz, Winnicka Anna, Witkowska-Piłaszewicz Olga, Grodzik Marta: Effects of Reduced Graphene Oxides on Apoptosis and Cell Cycle of Glioblastoma Multiforme, w: International Journal of Molecular Sciences, vol. 19, nr 12, 2018, ss. 3939-3939, ',
    point: '30',
    if: '4.183',
  },
  {
    articleName:
      'Matusiewicz Magdalena, Kosieradzka Iwona, Niemiec Tomasz, Grodzik Marta, Antushevich Hanna, Strojny-Cieślak Barbara, Gołębiewska Małgorzata: In Vitro influence of extracts from snail Helix aspersa Müller on the colon cancer cell line Caco-2, w: International Journal of Molecular Sciences, vol. 19, nr 4, 2018, ss. 1-23, DOI:10.3390/ijms19041064',
    point: '30',
    if: '4.183',
  },
  {
    articleName:
      'Vadalasetty Krishna, Lauridsen Charlotte, Engberg Ricarda, Vadalasetty Radhika, Kutwin Marta, Chwalibog André, Sawosz Chwalibóg Ewa: Influence of silver nanoparticles on growth and health of broiler chickens after infection with Campylobacter jejuni, w: BMC Veterinary Research, vol. 14, 2018, ss. 1-11, DOI:10.1186/s12917-017-1323-x',
    point: '40',
    if: '1.792',
  },
  {
    articleName:
      'Scott Abdullah, Vadalasetty Krishna, Chwalibog André, Sawosz Chwalibóg Ewa: Copper nanoparticles as an alternative feed additive in poultry diet: a review, w: Nanotechnology Reviews, vol. 7, nr 1, 2018, ss. 69-93, DOI:10.1515/ntrev-2017-0159',
    point: '25',
    if: '2.759',
  },
  {
    articleName:
      'Strojny-Cieślak Barbara, Sawosz Chwalibóg Ewa, Grodzik Marta, Jaworski Sławomir, Szczepaniak Jarosław, Sosnowska Malwina, Wierzbicki Mateusz, Kutwin Marta, Orlińska Sylwia, Chwalibog André: Nanostructures of diamond, graphene oxide and graphite inhibit cYP1a2, cYP2D6 and cYP3a4 enzymes and downregulate their genes in liver cells, w: International journal of nanomedicine, vol. 13, 2018, ss. 8561-8575, DOI:10.2147/IJN.S188997',
    point: '35',
    if: '4.471',
  },
  {
    articleName:
      'Padzik Marcin, Hendiger Edyta, Chomicz Lidia, Grodzik Marta, Szmidt Maciej, Grobelny Jarosław, Lorenzo-Morales Jacob: Tannic acid-modified silver nanoparticles as a novel therapeutic agent against Acanthamoeba, w: Parasitology Research, vol. 117, nr 11, 2018, ss. 3519-3525, DOI:10.1007/s00436-018-6049-6',
    point: '30',
    if: '2.067',
  },
  {
    articleName:
      'Sekretarska Justyna, Szczepaniak Jarosław, Sosnowska Malwina, Grodzik Marta, Kutwin Marta, Wierzbicki Mateusz, Jaworski Sławomir, Bałaban Jaśmina, Sawosz Chwalibóg Ewa, Strojny-Cieślak Barbara: Influence of selected carbon nanostructures on the CYP2C9 enzyme of the P450 cytochrome, w: Materials, MDPIAG, vol. 12, nr 24, 2019, ss. 1-14, DOI:10.3390/ma12244149, ',
    point: '140',
    if: '2.972',
  },
  {
    articleName:
      'Bossowska-Nowicka Magdalena, Mielcarska Matylda, Romaniewicz Marta, Kaczmarek Monika, Gregorczyk-Zboroch Karolina, Struzik Justyna, Grodzik Marta, Gieryńska Małgorzata, Toka Felix, Szulc-Dąbrowska Lidia: Ectromelia virus suppresses expression of cathepsins and cystatins in conventional dendritic cells to efficiently execute the replication process, w: BMC Microbiology, vol. 19, 2019, ss. 1-17, DOI:10.1186/s12866-019-1471-1',
    point: '70',
    if: '3.287',
  },
  {
    articleName:
      'Jaworski Sławomir, Strojny-Cieślak Barbara, Sawosz Chwalibóg Ewa, Wierzbicki Mateusz, Grodzik Marta, Kutwin Marta, Daniluk Karolina, Chwalibog André: Degradation of mitochondria and oxidative stress as the main mechanism of toxicity of pristine graphene on U87 glioblastoma cells and tumors and HS-5 cells, w: International Journal of Molecular Sciences, vol. 20, nr 3, 2019, ss. 1-17, DOI:10.3390/ijms20030650',
    point: '140',
    if: '4.183',
  },
  {
    articleName:
      'Wierzbicki Mateusz, Jaworski Sławomir, Sawosz Chwalibóg Ewa, Jung Anna, Gielerak Grzegorz, Jaremek Henryk, Łojkowski Witold, Woźniak Bartosz, Stobiński Leszek, Małolepszy Artur: Graphene Oxide in a Composite with Silver Nanoparticles Reduces the Fibroblast and Endothelial Cell Cytotoxicity of an Antibacterial Nanoplatform, w: Nanoscale Research Letters, Springer, vol. 14, 2019, ss. 1-11, DOI:10.1186/s11671-019-3166-9, ',
    point: '100',
    if: '3.159',
  },
  {
    articleName:
      'Matusiewicz Magdalena, Bączek Katarzyna, Kosieradzka Iwona, Niemiec Tomasz, Grodzik Marta, Szczepaniak Jarosław, Orlińska Sylwia, Węglarz Zenon: Effect of juice and extracts from Saposhnikovia divaricata root on the colon cancer cells Caco-2, w: International Journal of Molecular Sciences, vol. 20, nr 18, 2019, ss. 2-23, DOI:10.3390/ijms20184526',
    point: '140',
    if: '4.183',
  },
  {
    articleName:
      'Jankowski Jan, Kozłowski Krzysztof, Ognik Katarzyna, Zduńczyk Zenon, Otowski Kamil, Sawosz Chwalibóg Ewa, Juśkiewicz Jerzy: Redox and immunological status of turkeys fed diets with different levels and sources of copper, w: Annals of Animal Science, Instytut Zootechniki, vol. 19, nr 1, 2019, ss. 215-227, DOI:10.2478/aoas-2018-0054',
    point: '100',
    if: '1.515',
  },
  {
    articleName:
      'Sosnowska Malwina, Kutwin Marta, Jaworski Sławomir, Strojny-Cieślak Barbara, Wierzbicki Mateusz, Szczepaniak Jarosław, Łojkowski Maciej, Święszkowski Wojciech, Bałaban Jaśmina, Sawosz Chwalibóg Ewa: Mechano-signalling, induced by fullerene C60 nanofilms, arrests the cell cycle in the G2/M phase and decreases proliferation of liver cancer cells, w: International journal of nanomedicine, vol. 14, 2019, ss. 6197-6215, DOI:10.2147/IJN.S206934, ',
    point: '140',
    if: '4.471',
  },
  {
    articleName:
      'Kutwin Marta, Sawosz Chwalibóg Ewa, Jaworski Sławomir, Wierzbicki Mateusz, Strojny-Cieślak Barbara, Grodzik Marta, Sosnowska Malwina, Trzaskowski Maciej, Chwalibog André: Nanocomplexes of graphene oxide and platinum nanoparticles against colorectal cancer Colo205, HT-29, HTC-116, SW480, liver cancer HepG2, human breast cancer MCF-7, and adenocarcinoma LNCaP and human cervical Hela B cell lines, w: Materials, MDPIAG, vol. 12, nr 6, 2019, ss. 1-17, DOI:10.3390/ma12060909',
    point: '140',
    if: '2.972',
  },
  {
    articleName:
      'Grodzik Marta, Szczepaniak Jarosław, Strojny-Cieślak Barbara, Hotowy Anna, Wierzbicki Mateusz, Jaworski Sławomir, Kutwin Marta, Soltan Emilia, Mandat Tomasz, Lewicka Aneta: Diamond nanoparticles downregulate expression of CycD and CycE in glioma cells, w: Molecules, vol. 24, nr 8, 2019, ss. 1-16, DOI:10.3390/molecules24081549, ',
    point: '100',
    if: '3.06',
  },
  {
    articleName:
      'Szmidt Maciej, Stankiewicz Adrian, Urbańska Kaja, Jaworski Sławomir, Kutwin Marta, Wierzbicki Mateusz, Grodzik Marta, Burzyńska Beata, Góra Monika, Sawosz Chwalibóg Ewa: Graphene oxide down-regulates genes of the oxidative phosphorylation complexes in a glioblastoma, w: BMC Molecular Biology, vol. 20, nr 1, 2019, ss. 1-9, DOI:10.1186/s12867-018-0119-2, ',
    point: '70',
    if: '2.568',
  },
  {
    articleName:
      'Kalińska Aleksandra, Jaworski Sławomir, Wierzbicki Mateusz, Gołębiewski Marcin: Silver and copper nanoparticles - an alternative in future mastitis treatment and prevention?, w: International Journal of Molecular Sciences, vol. 20, nr 7, 2019, ss. 1-13, DOI:10.3390/ijms20071672',
    point: '140',
    if: '4.183',
  },
  {
    articleName:
      'Kotarba Grzegorz, Zielińska-Górska Marlena, Biernacka Katarzyna, Gajewska Alina: Gonadotropin-releasing hormone-Cu complex (Cu-GnRH) transcriptional activity in vivo in the female rat anterior pituitary gland, w: Brain Research Bulletin, vol. 159, 2020, ss. 67-75, DOI:10.1016/j.brainresbull.2020.01.005',
    point: '100',
    if: '3.103',
  },
  {
    articleName:
      'Łukasiewicz Monika, Łozicki Andrzej, Casey Norman Henry, Chwalibog Andre, Niemiec Jan, Matuszewski Arkadiusz, Sosnowska Malwina, Wierzbicki Mateusz, Zielińska-Górska Marlena, Sawosz Chwalibóg Ewa: Effect of zinc nanoparticles on embryo and chicken growth , and the content of zinc in tissues and faeces, w: South African Journal of Animal Science, vol. 50, nr 1, 2020, ss. 109-119, DOI:10.4314/sajas.50i1.12, ',
    point: '70',
    if: '0.653',
  },
  {
    articleName:
      'Wierzbicki Mateusz, Hotowy Anna, Kutwin Marta, Jaworski Sławomir, Bałaban Jaśmina, Sosnowska Malwina, Wójcik Barbara, Wędzińska Aleksandra, Chwalibog André, Sawosz Chwalibóg Ewa: Graphene Oxide Scaffold Stimulates Differentiation and Proangiogenic Activities of Myogenic Progenitor Cells, w: International Journal of Molecular Sciences, vol. 21, nr 11, 2020, ss. 4173, DOI:10.3390/ijms21114173',
    point: '140',
    if: '4.183',
  },
  {
    articleName:
      'Hendiger Edyta B., Padzik Marcin, Sifaoui Ines, Reyes-Batlle María, López-Arencibia Atteneri, Rizo-Liendo Aitor, Bethencourt-Estrella Carlos J., San Nicolás-Hernández Desirée, Chiboub Olfa, Grodzik Marta: Silver Nanoparticles as a Novel Potential Preventive Agent against Acanthamoeba Keratitis, w: Pathogens, vol. 9, nr 5, 2020, ss. 350, DOI:10.3390/pathogens9050350, ',
    point: '100',
    if: '3.405',
  },
  {
    articleName:
      'Bałaban Jaśmina, Wierzbicki Mateusz, Zielińska-Górska Marlena, Szczepaniak Jarosław, Sosnowska Malwina, Daniluk Karolina, Cysewski Dominik, Koczoń Piotr, Chwalibog André, Sawosz Chwalibóg Ewa: Effects of Graphene Oxide Nanofilm and Chicken Embryo Muscle Extract on Muscle Progenitor Cell Differentiation and Contraction, w: Molecules, vol. 25, nr 8, 2020, ss. 1-21, DOI:10.3390/molecules25081991',
    point: '100',
    if: '3.06',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Hotowy Anna, Wierzbicki Mateusz, Bałaban Jaśmina, Sosnowska Malwina, Jaworski Sławomir, Strojny-Cieślak Barbara, Chwalibog André, Sawosz Chwalibóg Ewa: Graphene oxide nanofilm and the addition of l-glutamine can promote development of embryonic muscle cells, w: Journal of Nanobiotechnology, vol. 18, nr 1, 2020, DOI:10.1186/s12951-020-00636-z',
    point: '140',
    if: '5.345',
  },
  {
    articleName:
      'Daniluk Karolina, Kutwin Marta, Grodzik Marta, Wierzbicki Mateusz, Strojny-Cieślak Barbara, Szczepaniak Jarosław, Bałaban Jaśmina, Sosnowska Malwina, Sawosz Chwalibóg Ewa, Jaworski Sławomir: Use of selected carbon nanoparticles as melittin carriers for MCF-7 and MDA-MB-231 human breast cancer cells, w: Materials, MDPIAG, vol. 13, nr 1, 2020, ss. 1-20, DOI:10.3390/ma13010090',
    point: '140',
    if: '2.972',
  },
  {
    articleName:
      'Hotowy Anna, Grodzik Marta, Zielińska-Górska Marlena, Chojnacka Natalia, Kurantowicz Natalia, Dyjak Sławomir, Strojny-Cieślak Barbara, Kutwin Marta, Chwalibog André, Sawosz Chwalibóg Ewa: Silver and Graphenic Carbon Nanostructures Differentially Influence the Morphology and Viability of Cardiac Progenitor Cells, w: Materials, MDPIAG, vol. 13, nr 9, 2020, ss. 2159, DOI:10.3390/ma13092159',
    point: '140',
    if: '2.972',
  },
  {
    articleName:
      'Lewicki Sławomir, Leśniak Monika, Sobolewska-Ruta Agnieszka, Lewicka Aneta, Grodzik Marta, Machaj Eugeniusz, Saracyn Marek, Kubiak Jacek, Pojda Zygmunt: Encapsulation of granulocyte colony-stimulating factor and granulocyte-macrophage colony-stimulating factor in liposomes prepared by thin film hydration and their transfer to mesenchymal stem cells and cord blood hematopoietic stem cells, w: Archives of Medical Science, DOI:10.5114/aoms.2020.94527',
    point: '–',
    if: '1.012',
  },
 {articleName: 'Matuszewski Arkadiusz, Łukasiewicz Monika, Łozicki Andrzej, Niemiec Jan, Zielińska-Górska Marlena, Scott A., Chwalibog Andre, Sawosz Ewa: The effect of manganese oxide nanoparticles on chicken growth and manganese content in excreta, w Animal Feed Science and Technology, 268, 114599. 2020; 10.1016/j.anifeedsci.2020.114597',
point: 200,
if: '3,078'},

{articleName: 'Matuszewski Arkadiusz, Łukasiewicz Monika, Niemiec Jan, Jaworski Sławomir, Kamaszewski Maciej, Szudrowicz Hubert, Puppel Kamila, Chwalibog André &amp; Sawosz Ewa. 2020. Effect of in ovo application of hydroxyapatite nanoparticles on chicken embryo development, oxidative status and bone characteristics. Archives of Animal Nutrition, 74, 343-361; DOI: 10.1080/1745039X.2020.1803033',
point: 100,
if: '1,96'},

{articleName: 'Hendiger EB, Padzik M, Żochowska A, Baltaza W, Olędzka G, Zyskowska D, Bluszcz J, Jarzynka S, Chomicz L, Grodzik M, Hendiger J, Piñero JE, Grobelny J, Ranoszek-Soliwoda K, Lorenzo- Morales J. Tannic acid-modified silver nanoparticles enhance the anti-Acanthamoeba activity of three multipurpose contact lens solutions without increasing their cytotoxicity. Parasit Vectors. 2020 Dec 22;13(1):624. DOI: 10.1186/s13071-020-04453-z.',
point: 100,
if: '3,169'},

{articleName: 'Kotela A, Lorkowski J, Chmielewski D, Grodzik M, Kotela I. Revision Hip Arthroplasty in Patient with Acetabulum Migration into Subperitoneal Space-A Case Report. Medicina (Kaunas). 2020 Dec 31;57(1):E30. doi: 10.3390/medicina57010030. PMID: 33396344.',

point: 40,
if: '1,205'},

{articleName: 'Strojny-Cieślak Barbara, Jaworski Sławomir, Misiewicz-Krzemińska Irena, Isidro Isabel, Rojas Elizabeta A., Gutiérrez Norma C., Grodzik Marta, Koczoń Piotr, Chwalibog André, Sawosz Ewa. 2020. Effect of Graphene Family Materials on Multiple Myeloma and Non-Hodgkin’s Lymphoma Cell Lines. Materials, Materials, 13 (15): 1–21. doi:10.3390/ma13153420.',
point: 140,
if: '3,424'},

{articleName: 'Lange A., Jaworski S., Khan A., 2020: The interaction of metal nanoparticles (copper, silver, platinum, and gold) with cell line HS-5. Folia Biologica (Kraków) 68: 89-96. doi.org/10.3409/fb_68-3.11',
point: 40,
if: '0,745'},
{articleName: 'Jan Jankowski, Kamil Otowski, Krzysztof Kozłowski, Piotr Pietrzak, Karolina Ferenc, Katarzyna Ognik, Jerzy Juśkiewicz, Ewa Sawosz, Zenon Zduńczyk. 2020. Effect of different levels of copper nanoparticles and copper sulfate on morphometric indices, antioxidant status and mineral digestibility in the small intestine of turkeys. Annales of Animal Sciences, 20, 3, 975- 990; DOI: 10.2478/aoas-2020-0013',
point: 100,
if: '1,325'},

{articleName: 'Misiewicz-Krzeminska I., de Ramón C., Corchete L.A., Krzeminski P., Rojas E.A., Isidro I., García-Sanz R., Martínez-López J., Oriol A., Bladé J., Lahuerta J.J., San Miguel J., Rosiñol L., Mateos M.V., Gutiérrez N.C. Quantitative expression of Ikaros, IRF4, and PSMD10 proteins predicts survival in VRD-treated patients with multiple myeloma. Blood advances: 4 (23) 6023-6033; DOI: 10.1182/bloodadvances.2020002711.',
point: 40,
if: '4,91'},
{articleName: 'Sosnowska Malwina, Kutwin Marta, Strojny-Cieślak Barbara, Koczoń Piotr, Szczepaniak Jarosław, Bałaban Jaśmina, Daniluk Karolina, Jaworski Sławomir, André Chwalibog, Bielawski Wiesław &amp; Ewa Sawosz: Graphene oxide nanofilm and chicken embryo extract decrease the invasiveness of HepG2 liver cancer cells, w: Cancer Nanotechnology, vol. 12, nr 1, 2021, ss. 1- 33, DOI:10.1186/s12645-020-00073-5',
point: 100,
if: '4,7'},

{articleName: 'Szczepaniak Jaroslaw , Jagiełło Joanna, Wierzbicki Mateusz, Nowak Dorota, Sobczyk-Guzenda Anna, Sosnowska Malwina, Jaworski Slawomir, Daniluk Karolina, Szmidt Maciej, Witkowska- Pilaszewicz Olga, Strojny-Cieslak Barbara, Grodzik Marta : Reduced Graphene Oxides Modulate the Expression of Cell Receptors and Voltage-Dependent Ion Channel Genes of Glioblastoma Multiforme , w: International Journal of Molecular Sciences, vol. 22, 2021, ss. 1-18, Numer artykułu:2, DOI:10.3390/ijms22020515',
point: 140,
if: '4,183'},
].reverse()
export const bookList = [
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Kosieradzka Iwona: (red.)Żywienie dzikich zwierząt :ssaki, 2012, Wydawnictwo SGGW, 524 s.',
  },
]
export const monoGraphs = [
  {
    articleName:
      'Zielińska-Górska Marlena, Grodzik Marta, Sawosz Chwalibóg Ewa, Szmidt Maciej, Michalska Elżbieta: Wpływ nanocząstek Ag na wybrane wskaźniki stanu zdrowia u przepiórki japońskiej, w: Fizjologia i biochemia w żywieniu zwierząt :IV konferencja młodych badaczy, Warszawa, 17-18 września 2007 r., 2007, Instytut Fizjologii i Żywienia Zwierząt im. Jana Kielanowskiego Polskiej Akademii Nauk, ss. 26-27',
    point: '3',
  },
  {
    articleName:
      'Lepianka Agnieszka, Sokół Janusz, Sawosz Chwalibóg Ewa: Hamujący wpływ nanocząstek wybranych metali na wzrost Salmonella Enteritidis chorobotwórczych dla kur - badania in vitro, w: Fizjologia i biochemia w żywieniu zwierząt :IV konferencja młodych badaczy, Warszawa, 17-18 września 2007 r., 2007, Instytut Fizjologii i Żywienia Zwierząt im. Jana Kielanowskiego Polskiej Akademii Nauk, ss. 94-95',
    point: '3',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa: Immunomodulacyjne właściwości srebra nanocząsteczkowego w badaniach na zarodkach kurzych, w: Fizjologia i biochemia w żywieniu zwierząt :IV konferencja młodych badaczy, Warszawa, 17-18 września 2007 r., 2007, Instytut Fizjologii i Żywienia Zwierząt im. Jana Kielanowskiego Polskiej Akademii Nauk, ss. 28-29',
    point: '3',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa: Influence of silver nanoparticle on choosen immune parametes of the chicken embryo, w: Science for poultry practice - poultry practice for science :XIX international poultry symposium PB WPSA : Olsztyn, 10-12 September 2007, 2007, ss. 64',
    point: '3',
  },
  {
    articleName:
      'Niemiec Tomasz, Szmidt Maciej, Sawosz Chwalibóg Ewa, Bakowicz-Mitura Katarzyna: Wpływ koloidu nanodiamentu podanego pozajelitowo na parametry stanu zdrowia szczurów, w: Fizjologia i biochemia w żywieniu zwierząt :V konferencja młodych badaczy, Warszawa, 22-23 września 2008 r., 2008, Instytut Fizjologii i Żywienia Zwierząt im. Jana Kielanowskiego Polskiej Akademii Nauk, ss. 83-86',
    point: '3',
  },
  {
    articleName:
      'Niemiec Tomasz, Lepianka Agnieszka, Rupiewicz Marlena, Sawosz Chwalibóg Ewa: Wpływ wydzielin larw Lucilla sericata (Muchówki: Calliphoridae) na wzrost pałeczek Escherichia coli i Salmonella enteritidis drobiu w badaniach in vitro, w: Fizjologia i biochemia w żywieniu zwierząt :V konferencja młodych badaczy, Warszawa, 22-23 września 2008 r., 2008, Instytut Fizjologii i Żywienia Zwierząt im. Jana Kielanowskiego Polskiej Akademii Nauk, ss. 55-57',
    point: '3',
  },
  {
    articleName:
      'Zielińska-Górska Marlena, Sawosz Chwalibóg Ewa, Grodzik Marta: Wpływ nanocząstek złota na rozwój układu krwionośnego zarodka kury. Badania modelowe, w: Fizjologia i biochemia w żywieniu zwierząt :V konferencja młodych badaczy, Warszawa, 22-23 września 2008 r., 2008, Instytut Fizjologii i Żywienia Zwierząt im. Jana Kielanowskiego Polskiej Akademii Nauk, ss. 155-156',
    point: '3',
  },
  {
    articleName:
      'Grodzik Marta, Sawosz Chwalibóg Ewa: Ocena szkodliwości koloidalnego roztworu nanocząstek srebra w badaniach na zarodkach kurzych, w: Fizjologia i biochemia w żywieniu zwierząt :V konferencja młodych badaczy, Warszawa, 22-23 września 2008 r., 2008, Instytut Fizjologii i Żywienia Zwierząt im. Jana Kielanowskiego Polskiej Akademii Nauk, ss. 157-159',
    point: '3',
  },
  {
    articleName:
      'Chwalibog André, Sawosz Chwalibóg Ewa: Energy and protein metabolism, w: Veterinary nutrition and dietetics / Fekete Sandor (red.), 2008, ss. 57-92',
    point: '3',
  },
  {
    articleName:
      "Sałamaszyńska-Guz Agnieszka, Grodzik Marta, Klimuszko Danuta: Identification of promoter sequence of the Campylobacter jejuni cj0183, w: X Jubilee Conference on 'Molecular biology in diagnostics of infectious diseases and biotechnology' :in memory of Professor Zbigniew M. Szynkiewicz (1926-1999) : Warsaw University of Life Science-SGGW, Warsaw-Cristal Hall, 28 November 2009., 2009, ss. 148-150",
    point: '3',
  },
  {
    articleName:
      "Sawosz Chwalibóg Ewa, Lepianka Agnieszka, Sokół Janusz, Grodzik Marta, Kizerwetter-Świda Magdalena, Binek Marian, Szeliga Jacek, Beck Iwona, Chwalibog André: Antymikrobiologiczne właściwości nanocząsteczek srebra w badaniach in vitro, w: Alfalfa in human and animal nutrition :new possibilities of application of the extract from the alfalfa leaves : monographic = Lucerna w żywieniu ludzi i zwierząt : nowe możliwości zastosowania ekstraktu z liści lucerny : monografie / Grela Eugeniusz (red.), Studia Regionalne i Lokalne Polski Południowo-Wschodniej, 2010, Stowarzyszenia Rozwoju Regionalnego i Lokalnego 'PROGRESS', ss. 148-155",
    point: '3',
  },
  {
    articleName:
      'Pineda L., Chwalibog A., Sawosz Chwalibóg Ewa, Hotowy Anna, Elnif J., Sawosz F., Niemiec Tomasz, Ali A.: Effect of nanoparticles of silver and gold on gas exchange and heat production of chicken embryos, w: EAAP Scientific Series, EAAP Scientific Series, 2010, ss. 213-214',
    point: '3',
  },
  {
    articleName:
      'Chwalibog A., Sawosz Chwalibóg Ewa, Metges C.C., Niemiec Tomasz, Grodzik Marta: Integration of 15N into DNA of liver of chicken embryos, w: EAAP Scientific Series, EAAP Scientific Series, 2010, ss. 139-140',
    point: '3',
  },
  {
    articleName:
      'Pineda L., Chwalibog A., Sawosz Chwalibóg Ewa, Hotowy Anna, Sawosz F., Elnif J., Ali A.: Nanoparticles of silver in broiler production: Effects on energy metabolism and growth performance, w: EAAP Scientific Series, EAAP Scientific Series, 2010, ss. 407-408',
    point: '3',
  },
  {
    articleName:
      'Zielińska M.K., Sawosz Chwalibóg Ewa, Chwalibog A., Grodzik Marta, Ostaszewska Teresa, Kamaszewski Maciej: Nano-nutrition in experiments with chicken embryos, w: EAAP Scientific Series, EAAP Scientific Series, 2010, ss. 251-252',
    point: '3',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Grodzik Marta, Niemiec Tomasz: Nanotechnologia w produkcji zwierzęcej, w: Chemia i biotechnologia w produkcji zwierzęcej / Grela Eugeniusz (red.), 2011, Powszechne Wydawnictwo Rolnicze i Leśne, ss. 207-213',
    point: '4',
  },
  {
    articleName:
      'Kosieradzka Iwona, Matusiewicz Magdalena, Szopa Jan: Potencjalna możliwość wykorzystania transgenicznego lnu z nadekspresją enzymów szlaku syntezy flawonoidów w żywieniu bydła, w: Perspektywy produkcji mleka i wołowiny w Polsce i na świecie :XX Szkoła Zimowa Hodowców Bydła, Zakopane, 19-23 marca 2012 r. / Szarek Jan, Adamczyk Krzysztof (red.), 2012, Instytut Zootechniki. Państwowy Instytut Badawczy, ss. 392-393',
    point: '4',
  },
  {
    articleName:
      'Grodzik Marta: Torbacze, w: Żywienie dzikich zwierząt :ssaki / Sawosz Chwalibóg Ewa, Kosieradzka Iwona (red.), 2012, Wydawnictwo SGGW, ss. 75-94',
    point: '4',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa: Drapieżne lądowe, w: Żywienie dzikich zwierząt :ssaki / Sawosz Chwalibóg Ewa, Kosieradzka Iwona (red.), 2012, Wydawnictwo SGGW, ss. 330-433',
    point: '4',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa, Grodzik Marta, Wierzbicki Mateusz, Jaworski Sławomir, Kutwin Marta: Krowy i nanobiotechnologia, w: Hodowla i chów bydła w badaniach naukowych i w praktyce :XXII Szkoła Zimowa Hodowców Bydła, Zakopane, 24-28.03.2014 / Gil Zygmunt, Adamczyk Krzysztof, Skrzyński Grzegorz (red.), 2014, Uniwersytet Rolniczy im. Hugona Kołłątaja w Krakowie, ss. 93-99',
    point: '–',
  },
  {
    articleName:
      'Strojny-Cieślak Barbara: Rozważania na temat odpowiedzialności i roli naukowca w dzisiejszym świecie. Problemy związane z prowadzeniem badań i wyjaśnianiem zjawisk - co w dzisiejszej nauce niepokoi młodych naukowców, w: Interdyscyplinarne spotkania z filozofią / Najder-Stefaniak Krystyna (red.), 2014, ISBN 978-83-62523-36-8, ss. 87-97',
    point: '–',
  },
  {
    articleName:
      'Sawosz Chwalibóg Ewa: Żywienie kotów, w: Żywienie zwierząt i paszoznawstwo.Podstawy szczegółowego żywienia zwierząt / Jamroz Dorota (red.), vol. T. 2, 2015, Wydawnictwo Naukowe PWN, ISBN 978-83-01-14279-7 (t.1-3), ss. 496-509',
    point: '–',
  },
  {
    articleName:
      'Chwalibog André, Sawosz Chwalibóg Ewa: Przemiana energii, w: Żywienie zwierząt i paszoznawstwo.Fizjologiczne i biochemiczne podstawy żywienia zwierząt / Jamroz Dorota (red.), vol. T. 1, 2015, Wydawnictwo Naukowe PWN, ISBN 978-83-01-14279-7 (t.1-3), ss. 299-325',
    point: '–',
  },
  {
    articleName:
      'Chwalibog André, Łukasiewicz Monika, Skot Abdullah, Bakowicz-Mitura Katarzyna, Niemiec Jan, Vadalasetty Krishna, Sawosz Chwalibóg Ewa: Do copper nanoparticles stimulate blood vessel development?, w: SENM 2017 :Smart Engineering of New Materials, 27-29 June 2017 Lodz, Poland : abstract book / Szymański Witold, Batory Damian (red.), 2017, University of Technology, ISBN 978-83-932197-5-9, ss. 44',
    point: '5',
  },
  {
    articleName:
      'Wasilewska-Dziubińska ELżbieta, Marynowska J, Litwiniuk Anna, Zielińska-Górska Marlena, Kalisz M, Martyńska Lidia, Chmielowska Magdalena, Gajewska Alina: Valproate (VPA and levetiracetam (LEV) affect gonadotrope genes mRNA expression in anterior pituitary gland of rat ex vivo [abstract], w: V Congress of Polish Society of Neuroendocrinology :21–22 September, 2018 Kraków : [akstracts]., 2018, ss. 638',
    point: '5',
  },
  {
    articleName:
      "Kosieradzka Iwona, Matusiewicz Magdalena: GMO czy NBT - nowe techniki hodowlane 'drugą szansą' inżynierii genetycznej w produkcji żywności i pasz?, w: II Krajowe Forum Nauka - Praktyce Bezpieczeństwo pasz :zrównoważone wykorzystanie ubocznych produktów przemysłowych w żywieniu zwierząt - system zarządzania środowiskiem, 29.11.2018 [streszczenia]., 2018, ss. 37-38",
    point: '5',
  },
].reverse()
